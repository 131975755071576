export type Action =
  | {
      type: 'NEW_PASSWORD';
      newPassword: string;
    }
  | {
      type: 'CHANGE_PASSWORD';
      newPassword: string;
      oldPassword: string;
    };

export const saveNewPasswordToStore = (newPassword: string) => {
  return {
      type: 'NEW_PASSWORD',
      newPassword
    };
};

export const saveChangePasswordToStore = (newPassword: string, oldPassword: string) => {
  return {
      type: 'CHANGE_PASSWORD',
      newPassword,
      oldPassword
    }
};
    