import { O2Button, O2Indent, O2Section, O2TextField, O2Loader, O2Checkbox } from 'o2-theme-react';
import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useHistory } from 'react-router-dom';
import { Session } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getApiPath } from '../../api/request-utils';
import * as SessionActions from '../../redux/actions/sessionActions';
import { emailIsValid } from '../../utils';
import { addDangerKahlMessage } from '../../redux/actions/kahlInfoActions';
import { getStore } from '../../redux/store/store';
import { updateEmail } from '../../redux/actions/contactPersonEditingActions';

interface StateProps {
  session: Session;
}

interface DispatchProps {
  updateEmail: (email: string, password: string) => void;
  getUserInfo: () => void;
}

interface Props extends StateProps, DispatchProps {}

const ChangeEmail: React.FC<Props> = ({updateEmail, session, getUserInfo}) => {
  const [ email, setEmail ] = useState<string>('');
  const [ emailAgain, setEmailAgain ] = useState<string>('');
  const [ emailAgainError, setEmailAgainError ] = useState<string>('');
  const [ password, setPassword ] = useState<string>('');
  const [ showPassword , setShowPassword ] = useState<boolean>(false);
  const history = useHistory();
  const [emailError, setEmailError] = useState<string>('');
  const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(true);
  const [ blank, setBlank ] = useState<boolean>(false);

  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setEmail(value);
    if (value === '' || emailAgain === '' || value === emailAgain) {
      setEmailAgainError('');
    } else {
      setEmailAgainError(I18n.t('ume.ext.changeEmail.emailAgainNotSame'));
    }
  };

  const emailAgainChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setEmailAgain(value);
    if (value === '' || email === '' || value === email) {
      setEmailAgainError('');
    } else {
      setEmailAgainError(I18n.t('ume.ext.changeEmail.emailAgainNotSame'));
    }
  };
  
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session.wasRequested) {
      if (session.userInfo.username === '' || session.userInfo.username === null) {
        window.location.href = getApiPath(`auth/casLogin?redirectTo=${encodeURIComponent(
          window.location.href
        )}&internalLogin=false&forceLogin=true`);
      } else {
        checkAuthorization();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ session.wasRequested, session.userInfo.username]);

  const checkAuthorization = () => {
    if (session.userInfo.subscriberType === 'O2PRP' && session.userInfo.koId !== null) {
      setIsLoadingUserData(false);
    } else {
      getStore().dispatch(addDangerKahlMessage('ID1306'));
      setBlank(true);
    }
  }

  const handleKeySubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      submitUpdateEmail();
    }
  };

  const submitUpdateEmail = () => {
    let valid = true;
    if (!emailIsValid(email)) {
      setEmailError(I18n.t('ume.ext.registration.start.email.error'));
      valid = false;
    }
    if (valid) {
      updateEmail(email, password);
    }
  }
  if (blank) {
    return (
      <O2Section></O2Section>
      )
  } else if (isLoadingUserData) {
    return (
      <O2Loader>{I18n.t('ume.ext.registration.start.loading')}</O2Loader>
      )
  } else {
    return (
      <O2Section>
        <O2Indent>
          <h1 className='o2-typo__heading'>{I18n.t('ume.ext.changeEmail.title')}</h1>
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            label={I18n.t('ume.ext.changeEmail.email')}
            controlSize='medium'
            type='text'
            data-test-id='change-email-email'
            value={email}
            onChange={emailChangeHandler}
            onKeyDown={handleKeySubmit}
            validationText={emailError}
            validationType={emailError ? 'error' : undefined}
          />
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            label={I18n.t('ume.ext.changeEmail.emailAgain')}
            controlSize='medium'
            type='text'
            data-test-id='change-email-email-again'
            value={emailAgain}
            onChange={emailAgainChangeHandler}
            onKeyDown={handleKeySubmit}
            validationText={emailAgainError}
            validationType={emailAgainError ? 'error' : undefined}
          />
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            label={I18n.t('ume.ext.changeEmail.password')}
            controlSize='medium'
            type={showPassword ? 'text' : 'password'}
            data-test-id='change-email-password'
            value={password}
            onChange={e => setPassword(e.currentTarget.value)}
            onKeyDown={handleKeySubmit}
          />
        </O2Indent>
        <O2Indent>
          <O2Checkbox 
            id="show-password"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
            label={I18n.t('ume.ext.changeEmail.showPassword')}
            data-test-id='change-email-show-password'
          />
        </O2Indent>
        <O2Indent standalone>
          <O2Button
            indentRight
            data-test-id='change-email-back'
            color="transparent"
            onClick={() => history.goBack()}
            >
              {I18n.t('ume.ext.changeEmail.linkBack')}
          </O2Button>
          <O2Button
            indentRight
            disabled={!Boolean(email !== session.userInfo.email && email.length > 0) || Boolean(email !== emailAgain || emailAgainError || password.length === 0)}
            data-test-id='change-email-submit'
            color='primary'
            onClick={submitUpdateEmail}
          >
            {I18n.t('ume.ext.changeEmail.action.confirm')}
          </O2Button>
        </O2Indent>
      </O2Section>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  session: state.session
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  updateEmail: (email: string, password: string) =>
    dispatch(updateEmail(email, password)),
  getUserInfo: () => dispatch(SessionActions.loadUserInfoUnprotected(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeEmail);
