import { Message } from '../reducers/types';
export type Action =
  | {
      type: 'MESSAGE_ADD';
      message: Message;
    }
  | {
      type: 'MESSAGE_REMOVE';
      id: number;
    };

export const addMessage = (message: string, type: 'success' | 'warning' | 'danger'): Action => {
  return { type: 'MESSAGE_ADD', message: { id: Date.now(), type: type, message: message } };
};

export const removeMessage = (id: number): Action => {
  return { type: 'MESSAGE_REMOVE', id: id };
};
