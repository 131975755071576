import { getAdobeDataLayer } from "o2-theme-react";
import { useSelector } from "react-redux";

const useAnalytics = () => {
  const { setFormSubmit, setPageLoaded } = getAdobeDataLayer();

  const session = useSelector<any, any>(
    state => state.session
  );

  const locale = useSelector<any, any>(
    state => state.sharedObjectSession?.sharedObjectSession?.generalLoginData?.userPreferences?.locale
  );

  const getLanguage = () => {
    if (!!locale) {
      return locale.replace(/_/, '-')
    } else {
      return 'CZ-cs'
    }
  }

  const pageLoaded = (pageId: string, pageTitle: string) => {
    let eventData: any = {
      pageId,
      pageTitle,
      language: [ getLanguage() ],
      themeVersion: '2.9.3',
    };

    if (session.userInfo.logged) {
      eventData.user = {
        userType: 'external',
        basicServiceType: (session.userInfo.subscriberType === 'O2PRP' ? 'prepaid' : 'postpaid'),
        userName: session.userInfo.username,
        msisdn: session.userInfo.contactPhone,
        userFirstName: session.userInfo.firstName,
        userLastName: session.userInfo.lastName,
      }
    }
    //JUST FOR TESTING PURPOSES
    console.log('ADOBE | setPageLoaded:');
    console.log({
      event: 'pageLoaded',
      ...eventData
    });
    setPageLoaded(eventData)
  }

  return { pageLoaded, setFormSubmit }
}

export default useAnalytics;