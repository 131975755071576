import { SharedObjectSession } from "../../api/types";

export type Action = {
  type: 'ADD_SHARED_OBJECTS';
  sharedObjectSession: SharedObjectSession;
};

export const addSharedObjectSessions = (sharedObjectSession: SharedObjectSession) => {
  return {
      type: 'ADD_SHARED_OBJECTS',
      sharedObjectSession: sharedObjectSession,
    };
};