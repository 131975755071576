import {
  getAdobeDataLayer,
  O2HeaderLink,
  O2Indent,
  O2Pane,
  O2Popover,
  O2PopoverBody,
  O2PopoverUserAction,
  O2PopoverUserAvatar,
  O2PopoverUserGroup,
  O2PopoverUserInfo,
  O2PopoverUserName,
} from 'o2-theme-react';
import * as React from 'react';
import { I18n } from 'react-i18nify-lite';

import { getApiPath } from '../../api/request-utils';
import { getState } from '../../redux/store/store';

interface UserInfoState {
  infoShown: boolean;
}

export default class UserInfo extends React.PureComponent<any, UserInfoState> {
  constructor(props: any) {
    super(props);
    this.state = {
      infoShown: false
    };
  }

  logout = () => {
    const { setLogout } = getAdobeDataLayer();
    setLogout();
    setTimeout(() => {
      window.location.href = getApiPath(`auth/casLogout?redirectTo=${encodeURIComponent(getApiPath('/api/auth/casLogin'))}`);
    }, 2000);
  };

  toggleInfo = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    this.setState({ infoShown: !this.state.infoShown });
  };

  render() {
    const apfThemeLocation = (window as any).g_apfThemeLocation;
    const userInfo = getState().session.userInfo;
    const userName =
      userInfo.firstName || userInfo.lastName ? userInfo.firstName + ' ' + userInfo.lastName : userInfo.username;

    return (
      <>
        {userInfo && (
          <>
            <O2HeaderLink
              id='o2-user-info'
              href='#o2-header--popover-moje-o2-contact-user'
              avatar={apfThemeLocation + '/images/header/customer-avatar-resident.png'}
              user={true}
              onClick={this.toggleInfo}
            >
            </O2HeaderLink>
            <O2Popover
              target='o2-user-info'
              className='o2-popover__user'
              placement='bottom'
              hideArrow={true}
              isOpen={this.state.infoShown}
              toggle={this.toggleInfo}
            >
              <O2PopoverBody>
                <O2Pane noBorder>
                  <O2Indent>
                    <O2PopoverUserAvatar src={apfThemeLocation + '/images/header/customer-avatar-resident.png'} />
                  </O2Indent>
                  <O2Indent>
                    <O2PopoverUserName>{userName}</O2PopoverUserName>
                    <O2PopoverUserInfo>{userInfo.email}</O2PopoverUserInfo>
                    {userInfo && userInfo.msisdn !== undefined && <O2PopoverUserInfo>{userInfo.msisdn}</O2PopoverUserInfo>}
                  </O2Indent>
                  <O2Indent standalone>
                    <O2PopoverUserGroup>
                      <O2PopoverUserAction href='/' onClick={() => {
                          if (userInfo.subscriberType === 'O2PRP') {
                            window.location.href = 'http://pwsc-a-moje.o2.cz/dashboard';
                          } else if (userInfo.subscriberType === 'O2POP') {
                            window.location.href = 'https://moje.o2.cz/web/o2/userdashboard';
                          }
                        }} icon='home'>
                        {I18n.t('ume.ext.header.info.home')}
                      </O2PopoverUserAction>
                      <O2PopoverUserAction href='/' onClick={() => {
                         if (userInfo.subscriberType === 'O2PRP') {
                          window.location.href = getApiPath('deeplink/public/v1/deeplink?command=KOSET');
                        } else if (userInfo.subscriberType === 'O2POP') {
                          window.location.href = getApiPath('deeplink/public/v1/deeplink?command=PRPDASH');
                        }
                        }} icon='settings'>
                        {I18n.t('ume.ext.header.info.settings')}
                      </O2PopoverUserAction>
                      <O2PopoverUserAction href='/' onClick={(e) => {
                        e.preventDefault();
                        this.logout();
                      }} icon='exit'>
                        {I18n.t('ume.ext.header.info.logout')}
                      </O2PopoverUserAction>
                    </O2PopoverUserGroup>
                  </O2Indent>
                  <O2Indent>
                    <O2PopoverUserInfo><a href={getApiPath('deeplink/public/v1/deeplink?command=UMEBILL')}>{I18n.t('ume.ext.header.info.billing')}</a></O2PopoverUserInfo>
                  </O2Indent>
                </O2Pane>
              </O2PopoverBody>
            </O2Popover>
          </>
        )}
      </>
    );
  }
}
