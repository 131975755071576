import { O2Layout, O2LayoutColumn, initScripts } from 'o2-theme-react';
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Snackbar from './common/Snackbar';
import Header from './header/Header';
import Footer from './footer/Footer';
import Routes from './Routes';
import { getState } from '../redux/store/store';
import KahlMessages from './libs/kahlMessages/kahlMessages';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { loadUserInfoUnprotected } from '../redux/actions/sessionActions';
import { useDispatch } from 'react-redux';

interface Props {}

const App: React.FC<Props> = () => {
  const { session, kahlInfo } = getState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserInfoUnprotected(false));
    initScripts((window as any).g_adobeLunchPath, (window as any).g_cmpId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <GoogleReCaptchaProvider reCaptchaKey={(window as any).g_recaptchaSiteKey}>
        <div className='o2-page'>
          <Header session={session} />
          <O2Layout>
            <O2LayoutColumn pos='center' func='main'>
              <Routes />
              <Footer />
            </O2LayoutColumn>
          </O2Layout>
          <KahlMessages kahlInfo={kahlInfo} />
          <Snackbar />
        </div>
      </GoogleReCaptchaProvider>
    </BrowserRouter>
  );
};

export default App;
