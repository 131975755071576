import { sendGet } from './request-utils';
import { UserInfo } from './types';

export default class SessionApi {
  static getUserInfo() {
    return sendGet<UserInfo>('auth/userInfoProtected', null, 'json');
  }

  static getUserInfoUnprotected() {
    return sendGet<UserInfo>('auth/userInfo', null, 'json');
  }
}
