import React from 'react';

interface IProps {
  onClick: () => void;
  active?: boolean;
  children: React.ReactNode;
};

const HeaderLink: React.FC<IProps> = ({ onClick, active = false, children }: IProps) => {
  return (
    <div 
      style={{ cursor: 'pointer' }} 
      className={'o2-header__link' + (active ? ' o2-header__link--active' : '')}
      onClick={onClick} 
    >
      {children}
    </div>
  );
};

export default HeaderLink;
