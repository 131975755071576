import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import NewPasswordPage from './pages/NewPasswordPage';
import PasswordPage from './pages/PasswordPage';
import RecoverPage from './pages/RecoverPage';
import RegisterPage from './pages/RegisterPage';
import ThanksCheckEmail from './pages/ThanksCheckEmail';
import ThanksRecover from './pages/ThanksRecover';
import VerifyCodeConfirm from './pages/VerifyCodeConfirm';
import VerifyPage from './pages/VerifyPage';
import ChangeName from './pages/ChangeName';
import ChangeContactPhone from './pages/ChangeContactPhone';
import ChangeEmail from './pages/ChangeEmail';
import RegistrationPostpaid from './pages/RegistrationPostpaid';
import EmailSendInformation from './pages/EmailSendInformation';
import FinishRegistrationPostpaid from './pages/FinishRegistrationPostpaid';
import OTPHighSecurity from './pages/OTPHighSecurity';

interface Props {}

const Routes: React.FC<Props> = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Redirect to='/ume' />
      </Route>
      <Route exact path='/ume' component={RegisterPage} />
      <Route path='/ume/register-postpaid' component={RegistrationPostpaid} />
      <Route path='/ume/postpaid-thanks/' component={EmailSendInformation} />
      <Route path='/ume/finish-registration/' component={FinishRegistrationPostpaid} />
      <Route path='/ume/enhanced-security/' component={OTPHighSecurity} />
      <Route path='/ume/password' component={PasswordPage} />
      <Route path='/ume/thanks/:token' component={ThanksCheckEmail} />
      <Route path='/ume/thanks-recover/:type' component={ThanksRecover} />
      <Route path='/ume/verify-code/:phone' component={VerifyCodeConfirm} />
      <Route path='/ume/verify' component={VerifyPage} />
      <Route path='/ume/recover' component={RecoverPage} />
      <Route path='/ume/new-password' component={NewPasswordPage} />
      <Route path='/ume/change-name' component={ChangeName} />
      <Route path='/ume/change-contact-phone' component={ChangeContactPhone} />
      <Route path='/ume/change-email' component={ChangeEmail} />
    </Switch>
  );
};

export default Routes;
