import { O2Button, O2Checkbox, O2Indent, O2Section, O2TextField } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import * as RegistrationActions from '../../redux/actions/contactPersonActions';
import { saveChangePasswordToStore } from '../../redux/actions/preOTPSpaceActions';
import * as SessionActions from '../../redux/actions/sessionActions';
import { Session, Verification } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { isPasswordValid } from '../../utils';
import HeadingWithInfo from '../common/HeadingWithInfo';
import useAnalytics from '../../hooks/useAnalytics';

interface StateProps {
  session: Session;
  verification: Verification;
}

interface DispatchProps {
  changePassword: (oldPassword: string, newPassword: string, otp?: string) => void;
  getUserInfo: () => void;
  saveChangePasswordToStore: (newPassword: string, oldPassword: string) => void;
}

interface Props extends StateProps, DispatchProps {}
//SCR05
const PasswordPage: React.FC<Props> = ({changePassword, saveChangePasswordToStore, session, verification }) => {
  const history = useHistory();
  const [isEnhanced, setIsEnhanced] = useState<boolean>(false);

  const [oldPass, setOldPass] = useState<string>('');
  const [newPass, setNewPass] = useState<string>('');
  const [newPassError, setNewPassError] = useState<string>('');

  const [newPassRepeated, setNewPassRepeated] = useState<string>('');
  const [newPassRepeatedError, setNewPassRepeatedError] = useState<string>('');

  const [ showPasswords, setShowPasswords ] = useState<boolean>(false);
  const { pageLoaded, setFormSubmit } = useAnalytics();

  useEffect(() => {
    pageLoaded('2.3.1.1.4', I18n.t('ume.ext.changePassword.description'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (session.userInfo.enhancedSecurity && verification.otpCode === '') {
      setIsEnhanced(true);
      
    }
  }, [ session, verification, history ])

  const handleKeySubmitSavePassword = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && newPass !== '' && newPass === newPassRepeated) {
      submitWithOTPredirect();
    }
  };

  const submitWithOTPredirect = () => {
    if (isEnhanced) {
      saveChangePasswordToStore(newPass, oldPass);
      history.push(`/ume/enhanced-security?target=${encodeURIComponent('NEW_PASSWORD_CHANGE')}`);
    } else {
      setFormSubmit('2.3.1.1.4');
      changePassword(oldPass, newPass)
    }
  }

  const handlePassChange = (newPassword: string, newPasswordRepeated: string) => {
    setNewPass(newPassword);
    setNewPassRepeated(newPasswordRepeated);
    setNewPassError(isPasswordValid(newPassword));

    if (newPassword !== '' && newPasswordRepeated !== '' && newPassword !== newPasswordRepeated) {
      setNewPassRepeatedError(I18n.t('ume.ext.changePassword.differentPassword'));
    } else {
      setNewPassRepeatedError('');
    }
  };

  if (session.processing) {
    return (
      <HeadingWithInfo headingText={I18n.t('ume.ext.changePassword.loading')}/>
    );
  } else if (session.notLogged) {
    return (
      <HeadingWithInfo headingText={I18n.t('ume.ext.changePassword.notLogged')} />
    );
  } else if (
    session.notLogged !== undefined &&
    session.notLogged === false &&
    session.deviceValidForPairing !== undefined &&
    session.deviceValidForPairing === false
  ) {
    return (
      <HeadingWithInfo headingText={I18n.t('ume.ext.registration.start.deviceValidateFail')} />
    );
  } else {
    return (
      <O2Section>
        <O2Indent>{I18n.t('ume.ext.changePassword.description')}</O2Indent>
        <O2Indent>
          <O2TextField
            inline
            indentRight
            indentBottom
            label={I18n.t('ume.ext.changePassword.oldPassword.label')}
            controlSize='medium'
            type={showPasswords ? 'text' : 'password'}
            data-test-id='password-current-password'
            value={oldPass}
            onChange={e => setOldPass(e.currentTarget.value)}
            onKeyDown={handleKeySubmitSavePassword}
          />
        </O2Indent>
        <O2Indent>
          <O2TextField
            style={{ verticalAlign: 'middle'}}
            inline
            indentRight
            indentBottom
            label={I18n.t('ume.ext.changePassword.password1.label')}
            controlSize='medium'
            type={showPasswords ? 'text' : 'password'}
            data-test-id='password-new-password'
            value={newPass}
            onChange={e => handlePassChange(e.currentTarget.value, newPassRepeated)}
            onKeyDown={handleKeySubmitSavePassword}
            validationType={newPassError !== '' ? 'error' : undefined}
            validationText={newPassError}
          />
        </O2Indent>
        <O2Indent>
            <O2TextField
              inline
              indentRight
              indentBottom
              label={I18n.t('ume.ext.changePassword.password2.label')}
              controlSize='medium'
              type={showPasswords ? 'text' : 'password'}
              data-test-id='password-new-password-repeat'
              value={newPassRepeated}
              onChange={e => handlePassChange(newPass, e.currentTarget.value)}
              onKeyDown={handleKeySubmitSavePassword}
              validationType={newPassRepeatedError !== '' ? 'error' : undefined}
              validationText={newPassRepeatedError}
            />
        </O2Indent>
        <O2Indent>
          <O2Checkbox
              label={I18n.t('ume.ext.changePassword.showPassword')}
              onChange={() => setShowPasswords(!showPasswords)}
          />
        </O2Indent>
        <O2Indent standalone>
          <O2Button
            data-test-id='password-new-submit'
            color='important'
            disabled={newPass === '' || newPass !== newPassRepeated}
            onClick={() => submitWithOTPredirect()}
          >
            {I18n.t('ume.ext.changePassword.action.confirm')}
          </O2Button>
          <O2Button indentRight color="transparent" onClick={() => history.goBack()}>
            {I18n.t('ume.ext.changePassword.action.cancel')}
          </O2Button>
        </O2Indent>
      </O2Section>
    );
  }
};

const mapStateToProps = (state: StoreState): StateProps => ({
  session: state.session,
  verification: state.verification
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  changePassword: (oldPassword: string, newPassword: string, otp?: string) =>
    dispatch(RegistrationActions.changePassword(oldPassword, newPassword, otp)),
  getUserInfo: () => dispatch(SessionActions.loadUserInfo()),
  saveChangePasswordToStore: (newPassword: string, oldPassword: string) =>
    dispatch(saveChangePasswordToStore(newPassword, oldPassword))
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPage);
