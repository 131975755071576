import { O2Addon, O2AddonContent, O2AddonPane, O2Icon, O2Indent, O2Pane, O2Section } from 'o2-theme-react';
import React, { FC, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import { RouteComponentProps } from 'react-router-dom';
import { getApiPath } from '../../api/request-utils';

import useAnalytics from '../../hooks/useAnalytics';

interface UrlProps {
  type: 'req' | 'renew';
}

interface IThanksRecover extends RouteComponentProps, UrlProps {}
//SCR07
const ThanksRecover: FC<IThanksRecover> = props => {
  const { pageLoaded } = useAnalytics();
  const urlProps = props.match.params as UrlProps;

  useEffect(() => {
    pageLoaded('2.6.3.2', I18n.t('ume.ext.requestPasswordReset.followEmail.header'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resolveHeaderText = () => {
    if (urlProps.type === 'renew') {
      return (
        <O2Indent>
          <h2 className='o2-typo--heading'>
            {I18n.t('ume.ext.requestPasswordReset.followEmail.renew.header')}
          </h2>
        </O2Indent>
      )
    }

    return (
      <O2Indent>
        <h2 className='o2-typo--heading'>
          {I18n.t('ume.ext.requestPasswordReset.followEmail.req.header')}
        </h2>
      </O2Indent>
    );
  }

  const resolveDescriptionText = () => {
    if (urlProps.type === 'renew') {
      return `${I18n.t('ume.ext.requestPasswordReset.followEmail.renew.description')} ${localStorage.getItem('email')}`;
    }
    return (
      <>
        {I18n.t('ume.ext.requestPasswordReset.followEmail.req.description')}
        <br />
        <br />
        <a style={{ color: "#0019a5" }} href={getApiPath(`auth/casLogin?redirectTo=${encodeURIComponent(window.location.href)}&internalLogin=false&forceLogin=true`)} className='o2-header__link'>
          {I18n.t('ume.ext.requestPasswordReset.followEmail.linkReq')}
        </a>
      </>
    )
  }

  return (
    <O2Section>
      <O2Pane color='success'>
        <O2Addon reversed>
          <O2AddonPane indent>
            <div className='o2-typo--heading-h1'>
              <O2Icon name='checkmark' size='lg' />
            </div>
          </O2AddonPane>
          <O2AddonContent>
            {resolveHeaderText()}
            <O2Indent>
              {resolveDescriptionText()}
            </O2Indent>
          </O2AddonContent>
        </O2Addon>
      </O2Pane>
    </O2Section>
  );
};

export default ThanksRecover;
