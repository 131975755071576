import { O2Button, O2Indent, O2Section, O2TextField, O2Loader } from 'o2-theme-react';
import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useHistory } from 'react-router-dom';
import { Session } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getApiPath } from '../../api/request-utils';
import * as SessionActions from '../../redux/actions/sessionActions';
import { updateContactPhone } from '../../redux/actions/contactPersonEditingActions';
import { isPhoneValid } from '../../utils';
import { addDangerKahlMessage } from '../../redux/actions/kahlInfoActions';
import { getStore } from '../../redux/store/store';

interface StateProps {
  session: Session;
}

interface DispatchProps {
  updateContactPhone: (koId: string, contactPhone: string) => void;
  getUserInfo: () => void;
}

interface Props extends StateProps, DispatchProps {}

const ChangeContactPhone: React.FC<Props> = ({updateContactPhone, session, getUserInfo}) => {
  const [ contactPhone, setContactPhone ] = useState<string>('');
  const history = useHistory();
  const [contactPhoneError, setContactPhoneError] = useState<string>('');
  const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(true);
  const [ blank, setBlank ] = useState<boolean>(false);
  
  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session.wasRequested) {
      if (session.userInfo.username === '' || session.userInfo.username === null) {
        window.location.href = getApiPath(`auth/casLogin?redirectTo=${encodeURIComponent(
          window.location.href
        )}&internalLogin=false&forceLogin=true`);
      } else {
        checkAuthorization();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ session.wasRequested, session.userInfo.username]);


  const checkAuthorization = () => {
    if (session.userInfo.subscriberType === 'O2PRP' && session.userInfo.koId !== null) {
      setIsLoadingUserData(false);

      if (session.userInfo.contactPhone && session.userInfo.contactPhone?.length > 9) {
        setContactPhone(session.userInfo.contactPhone ? session.userInfo.contactPhone.substr(session.userInfo.contactPhone.length - 9) : '');
      } else {
        setContactPhone(session.userInfo.contactPhone ? session.userInfo.contactPhone : '');
      }
      
    } else {
      getStore().dispatch(addDangerKahlMessage('ID1306'));
      setBlank(true);
    }
  }

  const isFormChanged = () => {
    if (
      session.userInfo.contactPhone &&
      contactPhone !== session.userInfo.contactPhone.substr(session.userInfo.contactPhone.length - 9)
    ) return true

    return false
  }

  const handleKeySubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && contactPhone.length > 8) {
      submitUpdateContactPhone();
    }
  };

  const submitUpdateContactPhone = () => {
    let valid = true;

    if (!isPhoneValid(contactPhone)) {
      valid = false;
      setContactPhoneError(I18n.t('ume.ext.registration.start.phone.notNumber'));
    }
    if (valid) {
      updateContactPhone(session.userInfo.koId ? session.userInfo.koId : '', contactPhone);
    }
  }

  if (blank) {
    return (
      <O2Section></O2Section>
      )
  } else if (isLoadingUserData) {
    return (
      <O2Loader>{I18n.t('ume.ext.registration.start.loading')}</O2Loader>
      )
  } else {
    return (
      <O2Section>
        <O2Indent>
          <h1 className='o2-typo__heading'>{I18n.t('ume.ext.changeContactPhone.title')}</h1>
        </O2Indent>
        <O2Indent>{I18n.t('ume.ext.changeContactPhone.description')}</O2Indent>
        <O2Indent>
          <O2TextField
            inline
            label={I18n.t('ume.ext.changeContactPhone.contactPhone')}
            controlSize='medium'
            type='text'
            data-test-id='change-contact-phone-contact-phone'
            value={contactPhone}
            onChange={e => setContactPhone(e.currentTarget.value)}
            onKeyDown={handleKeySubmit}
            validationText={contactPhoneError}
            validationType={contactPhoneError ? 'error' : undefined}
          />
        </O2Indent>
        <O2Indent standalone>
          <O2Button
            indentRight
            data-test-id='change-contact-phone-back'
            color="transparent"
            onClick={() => history.goBack()}
            >
              {I18n.t('ume.ext.changeContactPhone.linkBack')}
          </O2Button>
          <O2Button
            indentRight
            disabled={Boolean(contactPhone.length < 8) || !isFormChanged()}
            data-test-id='change-contact-phone-submit'
            color='primary'
            onClick={submitUpdateContactPhone}
          >
            {I18n.t('ume.ext.changeContactPhone.action.confirm')}
          </O2Button>
        </O2Indent>
      </O2Section>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  session: state.session
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  updateContactPhone: (koId: string, contactPhone: string) =>
    dispatch(updateContactPhone(koId, contactPhone)),
  getUserInfo: () => dispatch(SessionActions.loadUserInfoUnprotected(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeContactPhone);
