export type Action = {
  type: 'ADD_KAHL_MESSAGE';
  kahlMessageId: string | null;
  kahlMessageDefaultType: 'success' | 'danger' | null;
};

export const addSuccessKahlMessage = (kahlMessageId: string) => {
  return {
      type: 'ADD_KAHL_MESSAGE',
      kahlMessageId: kahlMessageId,
      kahlMessageDefaultType: 'success'
    };
};

export const addDangerKahlMessage = (kahlMessageId: string) => {
  return {
      type: 'ADD_KAHL_MESSAGE',
      kahlMessageId: kahlMessageId,
      kahlMessageDefaultType: 'danger'
    }
};
