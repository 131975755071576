import { SHARED_OBJECTS_API, sendGet, sendPatch } from './request-utils';
import { SharedObjectSession } from './types';


export default class SharedObjectApi {
  static getSharedObjectSession() {
    return sendGet<SharedObjectSession>(SHARED_OBJECTS_API +'session', null, 'json', false);
  }

  static patchShareObjectPreferencesLanguage(locale: string) {
    return sendPatch(SHARED_OBJECTS_API + 'preferences', null, {
      darkMode: false,
      vatPrice: true,
      locale: locale,
    });
  }
}
