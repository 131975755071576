import { PREPAID_INFO_API, PREPAID_MANAGE_API, sendGet, sendPut } from './request-utils';


export default class ContactPersonEditingApi {
  static updateName(koId: string, firstName: string, middleName: string, lastName: string) {
    return sendPut<string>(`${PREPAID_MANAGE_API}updateName/${koId}` , null, {
      firstName,
      middleName,
      lastName,
    });
  }

  static updateContactPhone(koId: string, contactPhone: string) {
    return sendPut<string>(`${PREPAID_MANAGE_API}updateContactPhone/${koId}/${contactPhone}`, null, null);
  }

  static updateEmail(email: string, password: string) {
    return sendPut<string>(
      `${PREPAID_MANAGE_API}updateEmail`,
      null,
      {
        email,
        password,
      }
    );
  }

  static getContactPersonData(koId: string) {
    return sendGet<any>(`${PREPAID_INFO_API}getContactPersonData`, { koId });
  };

}
