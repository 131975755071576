import {
  O2Button,
  O2Checkbox,
  O2FieldLabel,
  O2Indent,
  O2LayoutColumn,
  O2Pane,
  O2Section,
  O2TextField
} from 'o2-theme-react';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import * as ContactPersonAction from '../../redux/actions/contactPersonActions';
import * as PasswordAction from '../../redux/actions/passwordActions';
import { saveNewPasswordToStore } from '../../redux/actions/preOTPSpaceActions';
import * as VerificationAction from '../../redux/actions/verificationActions';
import { Verification } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { isNicknamePartOfPassword, isPasswordValid } from '../../utils';
import HeadingWithInfo from '../common/HeadingWithInfo';
import useAnalytics from '../../hooks/useAnalytics';

interface StateProps {
  verification: Verification;
}

const prefixTVSC1 = 'XTVMIG';
const prefixTVSC2 = 'UME_XTV';

const isXTVExceptionSkipEnhancedSecurity = (verification: Verification) => {
  if (
    verification.emailType?.verificationEmailType === 'PASSWORD_CHANGE' ||
    verification.emailType?.verificationEmailType === 'PASSWORD_RESET'
  ) {
    if (
      verification.emailType?.koId.includes(prefixTVSC1) ||
      verification.emailType?.koId.includes(prefixTVSC2)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

interface DispatchProps {
  verifySecret: (secret: string, acceptOnly: string[], setInvalidSecret: any) => void;
  createPassword: (password: string, verificationSecret: string, otp?: string) => void;
  confirmEmailChange: (password: string, verificationSecret: string, otp?: string) => void;
  resetPassword: (verificationToken: string, newPassword: string, otp?: string) => void;
  saveNewPasswordToStore: (newPassword: string) => void;
}

interface Props extends StateProps, DispatchProps {}
//SCR04
const NewPasswordPage: React.FC<Props> = ({
  verification,
  verifySecret,
  createPassword,
  confirmEmailChange,
  resetPassword,
  saveNewPasswordToStore
}) => {
  const [verSecret, setVerSecret] = useState<string>('');
  const [invalidSecret, setInvalidSecret] = useState<boolean>(false);
  const [isEnhanced, setIsEnhanced] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [repeatPasswordError, setRepeatPasswordError] = useState<string>('');

  const [showPasswords, setShowPasswords] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();

  const { pageLoaded, setFormSubmit } = useAnalytics();

  useEffect(() => {
    pageLoaded('2.6.3.1', I18n.t('ume.ext.createPassword.title'));
    const { secret } = queryString.parse(location.search);

    if (secret) {
      verifySecret(
        secret as string,
        ['NEW_CONTACT_PERSON', 'EMAIL_CHANGE', 'PASSWORD_CHANGE', 'PASSWORD_RESET'],
        setInvalidSecret
      );
      setVerSecret(secret as string);
    } else {
      verifySecret(
        'invalid',
        ['NEW_CONTACT_PERSON', 'EMAIL_CHANGE', 'PASSWORD_CHANGE', 'PASSWORD_RESET'],
        setInvalidSecret
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (verification.emailType?.enhancedSecurity || verification.emailType?.requireOtp) &&
      (verification.otpCode === undefined || verification.otpCode === '')
    ) {
      setIsEnhanced(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verification.emailType?.enhancedSecurity, verification.emailType?.requireOtp]);

  const handleKeySubmitSaveNewPassword = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && passwordError === '' && repeatPasswordError === '') {
      submitWithOTPredirect();
    }
  };

  const submitWithOTPredirect = () => {
    if (
      isEnhanced &&
      verification.emailType?.verificationEmailType !== 'NEW_CONTACT_PERSON' &&
      !isXTVExceptionSkipEnhancedSecurity(verification)
    ) {
      saveNewPasswordToStore(password);
      const { secret } = queryString.parse(location.search);
      history.push(
        `/ume/enhanced-security?secret=${secret}&target=${encodeURIComponent(
          verification.emailType?.verificationEmailType as string
        )}`
      );
    } else {
      setFormSubmit('2.6.3.1');
      registerPassword();
    }
  };

  const passwordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    let error = isPasswordValid(value);

    if (error === '') error = isNicknamePartOfPassword(verification.emailType?.email ?? '', value);

    setPasswordError(error);

    setPassword(value);
    if (value === '' || repeatPassword === '' || value === repeatPassword) {
      setRepeatPasswordError('');
    } else {
      setRepeatPasswordError(I18n.t('ume.ext.newPassword.differentPassword'));
    }
  };

  const repeatPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setRepeatPassword(value);

    if (password === '' || value === '' || password === value) {
      setRepeatPasswordError('');
    } else {
      setRepeatPasswordError(I18n.t('ume.ext.newPassword.differentPassword'));
    }
  };

  const registerPassword = () => {
    if (verification.emailType && verification.emailType?.verificationEmailType === 'NEW_CONTACT_PERSON') {
      createPassword(password, verSecret);
    } else if (verification.emailType && verification.emailType?.verificationEmailType === 'EMAIL_CHANGE') {
      confirmEmailChange(password, verSecret, verification.otpCode);
    } else if (
      verification.emailType &&
      (verification.emailType?.verificationEmailType === 'PASSWORD_CHANGE' ||
        verification.emailType?.verificationEmailType === 'PASSWORD_RESET')
    ) {
      if (isXTVExceptionSkipEnhancedSecurity(verification)) {
        resetPassword(verSecret, password);
      } else {
        resetPassword(verSecret, password, verification.otpCode);
      }
    }
  };

  if (verification.processing) {
    return (
      <HeadingWithInfo
        headingText={I18n.t('ume.ext.newPassword.loading')}
        descriptionText={I18n.t('ume.ext.registration.start.description')}
      />
    );
  } else {
    return (
      <O2LayoutColumn pos='narrow'>
        <O2Section>
          <O2Pane>
            <O2Indent>
              <O2FieldLabel block={true}>{I18n.t('ume.ext.createPassword.title')}</O2FieldLabel>
              <span>{verification.emailType && verification.emailType?.email}</span>
            </O2Indent>
            <O2Indent>
              <O2TextField
                inline
                type={showPasswords ? 'text' : 'password'}
                data-test-id='new-password-password'
                label={I18n.t('ume.ext.createPassword.password1.label')}
                controlSize='medium'
                note={I18n.t('ume.ext.createPassword.description')}
                value={password}
                onChange={passwordChangeHandler}
                onKeyDown={handleKeySubmitSaveNewPassword}
                validationText={passwordError}
                disabled={invalidSecret}
                validationType={passwordError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent>
              <O2TextField
                inline
                type={showPasswords ? 'text' : 'password'}
                data-test-id='new-password-password-repeat'
                label={I18n.t('ume.ext.createPassword.password2.label')}
                controlSize='medium'
                value={repeatPassword}
                onChange={repeatPasswordChangeHandler}
                onKeyDown={handleKeySubmitSaveNewPassword}
                disabled={invalidSecret}
                validationText={repeatPasswordError}
                validationType={repeatPasswordError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent>
              <O2Checkbox
                label={I18n.t('ume.ext.createPassword.showPassword')}
                disabled={invalidSecret}
                onChange={() => setShowPasswords(!showPasswords)}
              />
            </O2Indent>
            <O2Indent standalone>
              <O2Button
                data-test-id='new-password-submit'
                color='primary'
                disabled={passwordError !== '' || repeatPasswordError !== '' || invalidSecret}
                onClick={submitWithOTPredirect}
              >
                {I18n.t('ume.ext.newPassword.confirmPassword')}
              </O2Button>
            </O2Indent>
          </O2Pane>
        </O2Section>
      </O2LayoutColumn>
    );
  }
};

const mapStateToProps = (state: StoreState): StateProps => ({
  verification: state.verification
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  verifySecret: (verificationSecret: string, acceptOnly: string[], setInvalidSecret: any) =>
    dispatch(VerificationAction.verify(verificationSecret, acceptOnly, setInvalidSecret)),
  createPassword: (password: string, verificationSecret: string, otp?: string) =>
    dispatch(PasswordAction.createPassword(password, verificationSecret, otp)),
  confirmEmailChange: (password: string, verificationSecret: string, otp?: string) =>
    dispatch(ContactPersonAction.confirmEmailChange(password, verificationSecret, otp)),
  resetPassword: (verificationSecret: string, newPassword: string, otp?: string) =>
    dispatch(ContactPersonAction.resetPassword(verificationSecret, newPassword, otp)),
  saveNewPasswordToStore: (newPassword: string) => dispatch(saveNewPasswordToStore(newPassword))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewPasswordPage);
