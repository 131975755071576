import { Action } from "../actions/sharedObjectActions";
import { SharedObjectSessionStore } from "./types";

const initialState: SharedObjectSessionStore = {
  sharedObjectSession: null
};

export default (state: SharedObjectSessionStore = initialState, action: Action): SharedObjectSessionStore => {
  switch (action.type) {
    case 'ADD_SHARED_OBJECTS':
      return {
        ...state,
        sharedObjectSession: action.sharedObjectSession,
      };
    default:
      return state;
  }
}