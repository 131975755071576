import { Action } from '../actions/messageActions';
import { Message } from './types';

const initialState: Message[] = [];

export default (state: Message[] = initialState, action: Action): Message[] => {
  switch (action.type) {
    case 'MESSAGE_ADD':
      return [...state, action.message];
    case 'MESSAGE_REMOVE':
      return [...state.filter(message => message.id !== action.id)];
    default:
      return state;
  }
};
