import { Dispatch } from "react";
import ContactPersonEditingApi from "../../api/contact-person-editing-api";
import { checkDangerKahlMessages, redirectToStandaloneKahl } from "../../api/request-utils";
import { ContactPersonDataResponse } from "../reducers/types"

export type Action = 
  | {
      type: 'CHANGE_CONTACT_PHONE_START';
    }
  | {
      type: 'CHANGE_CONTACT_PHONE_SUCCESS';
    }
  | {
      type: 'CHANGE_CONTACT_PHONE_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'CHANGE_NAME_START';
    }
  | {
      type: 'CHANGE_NAME_SUCCESS';
    }
  | {
      type: 'CHANGE_NAME_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'CHANGE_EMAIL_START';
    }
  | {
      type: 'CHANGE_EMAIL_SUCCESS';
    }
  | {
      type: 'CHANGE_EMAIL_FAIL';
      error: string;
      code?: number;
    }
    | {
      type: 'GET_CONTACT_PERSON_DATA_START';
    }
  | {
      type: 'GET_CONTACT_PERSON_DATA_SUCCESS';
      data: ContactPersonDataResponse;
    }
  | {
      type: 'GET_CONTACT_PERSON_DATA_FAIL';
      error: string;
      code?: number;
    }

export const updateContactPhone = (
  koId: string,
  contactPhone: string,
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'CHANGE_CONTACT_PHONE_START'
    });

    return ContactPersonEditingApi.updateContactPhone(koId, contactPhone)
      .then(res => {
        //getStore().dispatch(addSuccessKahlMessage('ID0606'));
        window.location.replace((window as any).g_pwscCustomerSettings + 'ID0606');
        dispatch({
          type: 'CHANGE_CONTACT_PHONE_SUCCESS'
        });
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'CHANGE_CONTACT_PHONE_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const updateName = (
  koId: string,
  firstName: string,
  middleName: string,
  lastName: string,
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'CHANGE_NAME_START',
    });

    return ContactPersonEditingApi.updateName(koId, firstName, middleName, lastName)
      .then(res => {
        //getStore().dispatch(addSuccessKahlMessage('ID0586'));
        window.location.replace((window as any).g_pwscCustomerSettings + 'ID0586');
        dispatch({
          type: 'CHANGE_NAME_SUCCESS',
        });
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'CHANGE_NAME_FAIL',
          error: err.toString(),
          code: err.response && err.response.status,
        });
      });
  };
};

export const updateEmail = (
  newEmail: string,
  password: string,
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'CHANGE_EMAIL_START',
    });

    return ContactPersonEditingApi.updateEmail(newEmail, password)
      .then(res => {
        //getStore().dispatch(addSuccessKahlMessage('ID1434'));
        window.location.replace((window as any).g_pwscCustomerSettings + 'ID1434');
        dispatch({
          type: 'CHANGE_EMAIL_SUCCESS',
        });
      })
      .catch(err => {
        if (err.response && err.response.status === 403) {
          checkDangerKahlMessages({ resultCode: 'ID0590' });
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'CHANGE_EMAIL_FAIL',
          error: err.toString(),
          code: err.response && err.response.status,
        });
      });
  };
};

export const getContactPersonData = (
  koId: string,
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'GET_CONTACT_PERSON_DATA_START',
    });

    return ContactPersonEditingApi.getContactPersonData(koId)
      .then(res => {
        dispatch({
          type: 'GET_CONTACT_PERSON_DATA_SUCCESS',
          data: res.data,
        });
      })
      .catch(err => {
        checkDangerKahlMessages(err.response.data); 
        return dispatch({
          type: 'GET_CONTACT_PERSON_DATA_FAIL',
          error: err.toString(),
          code: err.response && err.response.status,
        });
      });
  };
};
