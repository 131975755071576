import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';

import { COOKIE_NAME } from '../constants/cookies';
import { clearCookies } from '../utils';
import { addSuccessKahlMessage, addDangerKahlMessage } from '../redux/actions/kahlInfoActions';
import { getStore } from '../redux/store/store';

export const PREPAID_MANAGE_API = 'ume/public/v1/prepaid/manage/';
export const PREPAID_INFO_API = 'ume/public/v1/prepaid/info/';
export const POSTPAID_MANAGE_API = 'ume/public/v1/postpaid/manage/';
export const VERIFICATION_API = 'ume/public/v1/verification/';
export const DEVICE_API = 'ume/public/v1/device/assign/';
export const COMMON_MANAGE_API = 'ume/public/v1/common/manage/';
export const SHARED_OBJECTS_API = 'shared-objects/public/v1/';

let refreshJwtTokenPromise: Promise<any> | null = null;

export function getApiPath(url: string): string {
  const apfApiLocation = (window as any).g_apfApiLocation;
  if (url.startsWith('/')) {
    return apfApiLocation + url;
  }
  return apfApiLocation + '/api/' + url;
}

function callJwtRefresh(authApiUrl: string, config: AxiosRequestConfig) {
  refreshJwtTokenPromise = new Promise((resolve, reject) => {
    axios
      .request({
        method: 'get',
        url: authApiUrl + '/refreshJwt',
        withCredentials: true
      })
      .then(() => {
        // console.log('Refresh request success');
        // Refresh was successful, resolve the promise
        refreshJwtTokenPromise = null;
        resolve();
      })
      .catch((err) => {
        // console.log('Refresh request fail x');
        // Refresh failed
        refreshJwtTokenPromise = null;
        console.log(err);
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          // 401 means that our session has expired and can no longer be refreshed,
          // so we'll redirect to login page
          redirectToLoginPage(config, err.response);
          reject('login_redirect');
        } else {
          // If refresh failed for any other reason than 401, we'll simply call original
          // error handler and let it handle it.
          reject('other');
        }
      });
  });
}

async function refreshJwtToken<R>(authApiUrl: string, config: AxiosRequestConfig): Promise<AxiosResponse<R>> {
  // Start JWT refresh call, if it's not yet active
  if (!refreshJwtTokenPromise) {
    callJwtRefresh(authApiUrl, config);
  }

  // Wait for JWT refresh call promise to resolve
  // console.log('Refresh wait start');
  try {
    await refreshJwtTokenPromise;
    // console.log('Refresh wait success');
  } catch (reason) {
    // If refresh failed with anything but login redirect, we'll try to send original
    // request again (it will most likely fail again, but let original request handler
    // deal with it).
    // In case of login redirect nothing needs to be done here.
    // console.log('XXXX Failed: ', reason);
    if (reason === 'login_redirect') {
      throw Error('Login redirect');
    }
  }
  // Refresh call was finished, now try to send original request again
  // console.log('Repeated request start');
  return processRequest(config, false);
}

function redirectToLoginPage(config: AxiosRequestConfig, response: any) {
  clearCookies(COOKIE_NAME);

  const authApiUrl = response.headers['x-auth-location'] || getApiPath('/api/auth');
  const redirectTo = `${authApiUrl}/casLogin?redirectTo=${encodeURIComponent(
    window.location.href
  )}&internalLogin=false`;
  window.location.href = redirectTo;
}

export function processRequest<R>(config: AxiosRequestConfig, allowJwtRefresh: boolean = true): AxiosPromise<R> {
  return axios.request<R>(config).catch(async (err) => {
    console.log(err);
    console.log(err.response);
    if (err.response && err.response.status === 401) {
      if (allowJwtRefresh) {
        const authApiUrl = err.response.headers['x-auth-location'] || getApiPath('/api/auth');
        const res = await refreshJwtToken<R>(authApiUrl, config);

        return res;
      }

      if (allowJwtRefresh) {
        redirectToLoginPage(config, err.response);
      }

      if (allowJwtRefresh) {
        throw Error('login_redirect');
      }
    }
    throw err;
  });
}

function prepareHeaders() {
  return {};
}

export function sendGet<R = any>(
  url: string,
  params: Object | null,
  responseType: 'json' | 'arraybuffer' | 'blob' | 'document' | 'text' | 'stream' | undefined = 'json',
  allowJwtRefresh = true
): AxiosPromise<R> {
  return processRequest<R>(
    {
      method: 'get',
      url: getApiPath(url),
      withCredentials: true,
      params: params,
      headers: prepareHeaders(),
      responseType: responseType
    },
    allowJwtRefresh
  );
}

export function sendPost<R = any>(url: string, params: Object | null, data: any): AxiosPromise<R> {
  return processRequest<R>({
    method: 'post',
    url: getApiPath(url),
    withCredentials: true,
    params: params,
    data: data,
    headers: prepareHeaders()
  });
}

export function sendPut<R = any>(url: string, params: Object | null, data: any): AxiosPromise<R> {
  return processRequest<R>({
    method: 'put',
    url: getApiPath(url),
    withCredentials: true,
    params: params,
    data: data,
    headers: prepareHeaders()
  });
}

export function sendPatch<R = any>(url: string, params: Object | null, data: any): AxiosPromise<R> {
  return processRequest<R>({
    method: 'patch',
    url: getApiPath(url),
    withCredentials: true,
    params: params,
    data: data,
    headers: prepareHeaders()
  });
}

export function sendDelete<R = any>(url: string, params: Object | null): AxiosPromise<R> {
  return processRequest<R>({
    method: 'delete',
    url: getApiPath(url),
    withCredentials: true,
    params: params,
    headers: prepareHeaders()
  });
}

export function checkSuccessKahlMessages(data: any) {
  if (data && data.resultCode) {
    getStore().dispatch(addSuccessKahlMessage(data.resultCode));
  }
}

export function checkDangerKahlMessages(err: any) {
  if (err && err.resultCode) {
    getStore().dispatch(addDangerKahlMessage(err.resultCode));
  } else {
    getStore().dispatch(addDangerKahlMessage('ID0013'));
  }
}

export const redirectToStandaloneKahl = (id: string, appId: string) => {
  if (
    getStore().getState().sharedObjectSession?.sharedObjectSession &&
    getStore()
      .getState()
      .sharedObjectSession?.sharedObjectSession?.generalLoginData.userPreferences.locale.includes('en')
  ) {
    window.location.href = getApiPath(`kahl/public/v1/kahl/standalone-message?appId=${appId}&msgId=${id}&locale=en`);
  } else {
    window.location.href = getApiPath(`kahl/public/v1/kahl/standalone-message?appId=${appId}&msgId=${id}`);
  }
};
