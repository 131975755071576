import { History } from 'history';
import {
  O2Addon,
  O2AddonContent,
  O2AddonPane,
  O2Button,
  O2Icon,
  O2Indent,
  O2Pane,
  O2Section,
  O2TextField,
} from 'o2-theme-react';
import React, { FC, useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import useAnalytics from '../../hooks/useAnalytics';

import * as DeviceActions from '../../redux/actions/deviceActions';
import { Session } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { isOTPValid } from '../../utils';

interface StateProps {
  session: Session;
}

interface DispatchProps {
  assignFinishMsisdn: (msisdn: string, otp: string, history: History) => void;
}

interface UrlProps {
  phone: string;
}

interface Props extends RouteComponentProps, UrlProps, DispatchProps, StateProps {}
//SCR03
const VerifyCodeConfirm: FC<Props> = ({ match, session, assignFinishMsisdn }) => {
  const urlProps = match.params as UrlProps;

  const [otp, setOtp] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');

  const history = useHistory<History>();
  const { pageLoaded, setFormSubmit } = useAnalytics();

  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setOtpError('');

    if (!isOTPValid(value)) {
      setOtpError(I18n.t('ume.ext.pairingNumber.action.OTPBadFormat'));
    }

    setOtp(value);
  };

  useEffect(() => {
    pageLoaded('666.1.10.3', I18n.t('ume.ext.verifyOtp.title'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeySubmitVerifyCode = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && otp.length === 6) {
      submitHandleVerify();
    }
  };

  const submitHandleVerify = () => {
    setFormSubmit('666.1.10.3');
    assignFinishMsisdn(urlProps.phone, otp, history);
  }

  return (
    <O2Section>
      <O2Indent>
        <O2Pane color='success'>
          <O2Addon reversed>
            <O2AddonPane indent>
              <div className='o2-typo--heading-h1'>
                <O2Icon name='checkmark' size='xl' />
              </div>
            </O2AddonPane>
            <O2AddonContent>
              <O2Indent>
                <h2 className='o2-typo--heading'>{I18n.t('ume.ext.verifyOtp.title')}</h2>
              </O2Indent>
              <O2Indent>{urlProps.phone}</O2Indent>
            </O2AddonContent>
          </O2Addon>
        </O2Pane>
      </O2Indent>
      <O2Indent>
        <O2Indent>
          <O2TextField
            inline
            data-test-id='verify-code-security-code'
            label={I18n.t('ume.ext.pairingNumber.action.securityCode')}
            controlSize='medium'
            value={otp}
            onChange={handleOTPChange}
            onKeyDown={handleKeySubmitVerifyCode}
            validationText={otpError}
            validationType={otpError ? 'error' : undefined}
          />
        </O2Indent>
        <O2Indent>
          <O2Button
            data-test-id='verify-code-submit'
            indentRight
            color="primary"
            fullWidth="xs"
            indentBottom
            disabled={otp.length > 0 ? false : true}
            onClick={submitHandleVerify}
          >
            {I18n.t('ume.ext.pairingNumber.action.submit')}
          </O2Button>
          <O2Button
            color="transparent"
            fullWidth="xs"
            indentBottom
            onClick={() => history.goBack()}
          >
            {I18n.t('ume.ext.pairingNumber.action.back')}
          </O2Button>
        </O2Indent>
      </O2Indent>
    </O2Section>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  session: state.session
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  assignFinishMsisdn: (msisdn: string, otp: string, history: History) =>
    dispatch(DeviceActions.assignFinishMsisdn(msisdn, otp, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyCodeConfirm);
