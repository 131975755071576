import { History } from 'history';
import { O2Button, O2Indent, O2Section, O2TextField, O2Loader, O2Icon } from 'o2-theme-react';
import React, { FC, useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import * as ContactPersonActions from '../../redux/actions/contactPersonActions';
import * as SessionActions from '../../redux/actions/sessionActions';
import { Registration, Session } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { emailIsValid, isNameValid, isPhoneValid } from '../../utils';
import HeadingWithInfo from '../common/HeadingWithInfo';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useAnalytics from '../../hooks/useAnalytics';

interface StateProps {
  registration: Registration;
  session: Session;
}

interface DispatchProps {
  registerUser: (
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    contactPhone: string,
    history: History,
    googleToken: string,
  ) => void;
  getUserInfo: () => void;
}

interface Props extends StateProps, DispatchProps {}
//SCR00
const RegisterPage: FC<Props> = ({ registerUser, registration, getUserInfo, session }) => {
  const [firstName, setFirstName] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [middleName, setMiddleName] = useState<string>('');
  const [middleNameError, setMiddleNameError] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [contactPhone, setContactPhone] = useState<string>('');
  const [contactPhoneError, setContactPhoneError] = useState<string>('');
  const history = useHistory<History>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { pageLoaded, setFormSubmit } = useAnalytics();

  useEffect(() => {
    getUserInfo();
    pageLoaded('666.1.10.1', I18n.t('ume.ext.registration.start.header'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeySubmitRegistration = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && isFormFilled()) {
      submitRegister();
    }
  };

  const isFormFilled = () => {
    return firstName.length > 0 && lastName.length > 0 && email.length > 0 && contactPhone.length > 0;
  };

  const registerHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmit('666.1.10.1');
    submitRegister();
  };

  const submitRegister = () => {
    let valid = true;

    if (!emailIsValid(email)) {
      setEmailError(I18n.t('ume.ext.registration.start.email.error'));
      valid = false;
    }

    if (!isPhoneValid(contactPhone)) {
      setContactPhoneError(I18n.t('ume.ext.registration.start.phone.notNumber'));
      valid = false;
    }

    if (!isNameValid(firstName)) {
      setFirstNameError(I18n.t('ume.ext.registration.start.firstName.error'));
      valid = false;
    }

    if (!isNameValid(lastName)) {
      setLastNameError(I18n.t('ume.ext.registration.start.firstName.error'));
      valid = false;
    }

    if (!isNameValid(middleName) && middleName !== '') {
      setMiddleNameError(I18n.t('ume.ext.registration.start.firstName.error'));
      valid = false;
    }

    if (valid) {
      if (executeRecaptcha) {
        executeRecaptcha('umeRegistration').then((token: any) => {
          registerUser(firstName, middleName, lastName, email, contactPhone, history, token);
        });
      }
    }
  }

  if (session.userInfo.username === '') {
    return (
      <O2Loader>{I18n.t('ume.ext.registration.start.loading')}</O2Loader>
      )
  } else if (
    session.notLogged !== undefined &&
    session.notLogged === false &&
    session.deviceValidForPairing !== undefined &&
    session.deviceValidForPairing === false
  ) {
    return (
      <HeadingWithInfo headingText={I18n.t('ume.ext.registration.start.deviceValidateFail')} />
    );
  } else {
    return (
      <>
        <form onSubmit={registerHandler}>
          <O2Section>
            <O2Indent>
              <h1 className='o2-typo__heading'>{I18n.t('ume.ext.registration.start.header')}</h1>
            </O2Indent>
            <O2Indent>{I18n.t('ume.ext.registration.start.description')}</O2Indent>
            <O2Indent>
              <O2TextField
                inline
                data-test-id='register-first-name'
                label={I18n.t('ume.ext.registration.start.firstName.label')}
                controlSize='medium'
                value={firstName}
                onChange={e => {
                  setFirstNameError('');
                  setFirstName(e.currentTarget.value);
                }}
                onKeyDown={handleKeySubmitRegistration}
                disabled={registration.registered || registration.processing}
                validationText={firstNameError}
                validationType={firstNameError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent>
              <O2TextField
                inline
                data-test-id='register-middle-name'
                label={I18n.t('ume.ext.registration.start.middleName.label')}
                controlSize='medium'
                value={middleName}
                onChange={e => {
                  setMiddleNameError('');
                  setMiddleName(e.currentTarget.value);
                }}
                onKeyDown={handleKeySubmitRegistration}
                disabled={registration.registered || registration.processing}
                validationText={middleNameError}
                validationType={middleNameError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent>
              <O2TextField
                inline
                data-test-id='register-last-name'
                label={I18n.t('ume.ext.registration.start.lastName.label')}
                controlSize='medium'
                value={lastName}
                onChange={e => {
                  setLastNameError('');
                  setLastName(e.currentTarget.value);
                }}
                onKeyDown={handleKeySubmitRegistration}
                disabled={registration.registered || registration.processing}
                validationText={lastNameError}
                validationType={lastNameError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent>
              <O2TextField
                inline
                indentRight
                data-test-id='register-email'
                label={I18n.t('ume.ext.registration.start.email.label')}
                controlSize='medium'
                value={email}
                onChange={e => {
                  setEmailError('');
                  setEmail(e.currentTarget.value);
                }}
                onKeyDown={handleKeySubmitRegistration}
                disabled={registration.registered || registration.processing}
                validationText={emailError}
                validationType={emailError ? 'error' : undefined}
              />
              <O2Icon name="info-fill" title={I18n.t('ume.ext.registration.start.email.tooltip')} />
            </O2Indent>
            <O2Indent>
              <O2TextField
                inline
                data-test-id='register-phone'
                label={I18n.t('ume.ext.registration.start.contactPhone.label')}
                type='text'
                placeholder={I18n.t('ume.ext.registration.start.contactPhone.placeholder')}
                value={contactPhone}
                onChange={e => {
                  setContactPhoneError('');
                  setContactPhone(e.currentTarget.value.replace(/\s/g,''));
                }}
                onKeyDown={handleKeySubmitRegistration}
                disabled={registration.registered || registration.processing}
                validationText={contactPhoneError}
                validationType={contactPhoneError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent standalone>
              <O2Button
                data-test-id='register-submit'
                color='primary'
                type='submit'
                loading={registration.processing ? 'right' : undefined}
                disabled={!isFormFilled() || registration.registered}
              >
                {I18n.t('ume.ext.registration.start.action.register')}
              </O2Button>
            </O2Indent>
          </O2Section>
        </form>
      </>
    );
  }
};

const mapStateToProps = (state: StoreState): StateProps => ({
  registration: state.registration,
  session: state.session
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  registerUser: (
    firstName: string,
    middleName: string,
    lastName: string,
    email: string,
    contactPhone: string,
    history: History,
    googleToken: string,
  ) => dispatch(ContactPersonActions.register(firstName, middleName, lastName, email, contactPhone, history, googleToken)),
  getUserInfo: () => dispatch(SessionActions.loadUserInfoUnprotected(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
