export default {
  ume: {
    ext: {
      createPassword: {
        title: 'Přihlašovací e-mail',
        description:
          'Heslo musí mít nejméně 6 znaků. Musí obsahovat aspoň jedno malé písmeno, jedno velké písmeno a jedno číslo.',
        password1: {
          label: 'Nové heslo'
        },
        password2: {
          label: 'Nové heslo ještě jednou'
        },
        showPassword: 'Zobrazit hesla',
        action: {
          confirm: 'Pokračovat'
        }
      },
      registration: {
        start: {
          loading: 'Načítání',
          header: 'Zvolte si bezpečné heslo pro přihlášení do Moje O2',
          description: 'Vytvořte si O2 ID pro jednotný přístup k aplikacím a službám O2.',
          firstName: {
            label: 'Křestní jméno',
            error: 'Jméno obsahuje zakázané znaky'
          },
          middleName: {
            label: 'Prostřední jméno'
          },
          lastName: {
            label: 'Příjmení'
          },
          email: {
            label: 'E-mail',
            error: 'Tento e-mail není validní',
            tooltip:
              'Tento e-mail bude později sloužit pro přihlášení do Moje O2 jako O2 ID a při obnově zapomenutých přihlašovacích údajů.'
          },
          contactPhone: {
            label: 'Kontaktní telefonní číslo',
            placeholder: 'Např. 777222111'
          },
          action: {
            register: 'Pokračovat'
          },
          phone: {
            toShort: 'Číslo je příliš krátké',
            toLong: 'Číslo je příliš dlouhé',
            notNumber: 'Zadejte číslo ve správném formátu.'
          },
          deviceValidateFail: 'Vaše zařízení není způsobilé k párování',
          contactValidateFail: 'Kontaktní osoba není způsobilá k párování.',
          alreadyPaired: 'Již máte napárované číslo'
        },
        followEmail: {
          req: {
            header: '',
            description: 'Nyní stačí kliknout na odkaz, který jsme vám zaslali na e-mail'
          },
          renew: {
            header: 'Výborně, vaše registrace je téměř hotová.',
            description: 'Nyní stačí kliknout na odkaz, který jsme vám zaslali na e-mail'
          },
          here: 'zde',
          resendSuccess: 'E-mail byl úspěšně odeslán',
          resendFail: 'E-mail se nepodařilo odeslat',
          description: 'Na e-mailovou adresu %{email} jsme vám odeslali odkaz k potvrzení.',
          resendDescription: 'Pokud vám e-mail nedorazil, nechte si ho odeslat znovu.',
          resend: 'Znovu odeslat e-mail'
        },
        statuses: {
          conflict: 'E-mail je již zaregistrovaný',
          error: 'Nepodařilo se registrovat'
        }
      },
      postpaid: {
        register: {
          title: 'Vytvoření nového účtu',
          description: 'Vytvoření účtu získáte přístup k vyúčtování a správě vašich služeb v Moje O2.',
          firstname: 'Křestní jméno',
          lastname: 'Příjmení',
          contactPhone: {
            label: 'Telefoní číslo (mobil)',
            hint: 'Na toto telefonní číslo vám může přijít SMS',
            placeholder: 'Např. 777 222 111'
          },
          email: 'E-mail, kterým se budete přihlašovat',
          emailVerify: 'Pro ověření zadejte e-mail znovu',
          submit: 'Pokračovat'
        },
        registration: {
          email: {
            sent: 'Výborně. Teď se ještě podívejte do e-mailu. Na váš e-mail jsme právě poslali odkaz pro dokončení registrace. Stačí na něj kliknout, nastavit si heslo a máte hotovo.'
          }
        },
        registerFinish: {
          title: 'K dokončení registrace do Moje O2 zbývá už jen krůček',
          description: 'Stačí nastavit heslo a zkontrolovat, jestli je vše správně, a máte hotovo.',
          email: 'E-mail, kterým se budete přihlašovat'
        },
        password: {
          label: 'Nastavte si heslo'
        },
        passwordVerify: {
          label: 'Zopakujte heslo ještě jednou',
          showPassword: 'Zobrazit hesla',
          action: {
            confirm: 'Dokončit registraci'
          }
        }
      },
      enhancedSecurity: {
        otp: {
          otpSend: 'Právě jsme vám poslali SMS s ověřovacím kódem.',
          text: 'Zadejte ověřovací kód, který jsme vám zaslali na telefoní číslo',
          resend: 'Poslat ověřovací kód znovu',
          sending: 'Ověřovací kód se odesílá...',
          continue: 'Pokračovat',
          cancel: 'Zrušit',
          input: {
            label: 'Ověřovací kód z SMS'
          },
          contactPhone: {
            text: 'Jak změnit telefonní číslo pro zaslání kódu',
            instructions:
              'Ověřovací kód můžeme poslat jen na vaše kontaktní telefonní číslo. To změníte v Nastavení kontaktní osoby v Moje O2. Pokud se do Nastavení nedostanete, ozvěte se nám, prosím, na bezplatnou linku 800 02 02 02 nebo v chatu.',
            close: 'Rozumím',
            openChat: 'OTEVŘÍT O2 GURU CHAT'
          }
        }
      },
      newPassword: {
        title: 'Zvolte si bezpečné heslo pro přihlášení do Moje O2',
        description: 'Vytvořte si O2 ID pro jednotný přístup k aplikacím a službám O2.',
        differentPassword: 'Hesla se neshodují',
        operationFailed: 'Operace se nezdařila, zkuste to prosím později',
        linkIsNotValid: 'Odkaz není validní',
        confirmPassword: 'Potvrdit heslo',
        passwordToConfirm: 'Zadejte znovu heslo pro ověření',
        password: 'Heslo',
        loading: 'Načítání',
        passwordMin: 'Heslo musí mít alespoň 6 znaků',
        passwordMax: 'Maximální délka hesla je 25 znaků',
        passwordRules: 'Heslo obsahuje zakázané znaky nebo není komplexní'
      },
      changePassword: {
        description: 'Pamatujte, že novým heslem se budete přihlašovat třeba i do O2 TV.',
        oldPassword: {
          label: 'Stávající heslo'
        },
        password1: {
          label: 'Nové heslo'
        },
        password2: {
          label: 'Nové heslo ještě jednou'
        },
        action: {
          confirm: 'Uložit',
          cancel: 'Zpět'
        },
        differentPassword: 'Hesla se neshodují',
        operationFailed: 'Operace se nezdařila, zkuste to prosím později',
        linkIsNotValid: 'Odkaz není validní',
        notLogged: 'Nejste přihlášeni',
        confirmPassword: 'Potvrdit heslo',
        passwordToConfirm: 'Zadejte znovu heslo pro ověření',
        password: 'Heslo',
        loading: 'Načítání',
        showPassword: 'Zobrazit hesla',
        passwordMin: 'Heslo musí mít alespoň 6 znaků',
        passwordMax: 'Maximální délka hesla je 25 znaků',
        passwordRules: 'Heslo obsahuje zakázané znaky',
        passwordComplex: 'Heslo musí obsahovat minimálně jedno malé písmeno, velké písmeno a číslo',
        passwordNickname:
          'Heslo nesmí začínat jako vaše uživatelské jméno a také nesmí být obrácenou verzí vašeho uživatelského jména'
      },
      pairingNumber: {
        header: 'Nakonec je potřeba rychlé ověření',
        loading: 'Načítání',
        notLogged: 'Nejste přihlášeni',
        msisdn: {
          label: 'O2 telefonní číslo',
          tooltip: 'Číslo vyplňte ve formátu 602123456.',
          hint: 'Zadejte telefonní číslo O2 předplacené karty, kterou chcete v Moje O2 spravovat.'
        },
        action: {
          sendOTP: 'Odeslat bezpečnostní kód',
          sendOTPSet:
            'Zadané telefonní číslo je v pořádku ověřeno. Zároveň jsme Vám na něj zaslali bezpečnostní kód, který prosím zadejte níže a potvrďte tlačítkem "Dokončit registraci"',
          securityCode: 'Bezpečnostní kód',
          OTPBadFormat: 'Bezpečnostní kód není ve správném tvaru',
          submit: 'Dokončit ověření',
          back: 'Zpět'
        },
        method: {
          label: 'Vyberte si způsob ověření',
          OTP: 'Přes SMS kód',
          PUK: 'Přes Puk kód'
        },
        PUK: {
          label: 'Zadejte váš PUK kód',
          format: 'PUK kód není ve správném tvaru',
          submit: 'Dokončit ověření'
        },
      },
      verifyOtp: {
        title: 'O2 telefoní číslo',
        description:
          'Zadané telefonní číslo je v pořádku ověřeno. Zároveň jsme Vám na něj zaslali bezpečnostní kód, který prosím zadejte níže a potvrďte tlačítkem "Dokončit registraci"',
        fail: 'Číslo se nepodařilo ověřit',
        otp: {
          hint: 'Bezpečnostní kód'
        },
        action: {
          finish: 'Dokončit registraci'
        }
      },
      requestPasswordReset: {
        start: {
          title: 'Zapomněli jste heslo?',
          description: 'Zadejte vaši e-mailovou adresu (O2 ID) a my vám pošleme odkaz na změnu hesla.',
          email: {
            label: 'Zadejte přihlašovací e-mail (O2 ID)'
          },
          emailRepeat: {
            label: 'Pro ověření zadejte e-mail znovu',
            notSame: 'E-maily musí být stejné'
          },
          action: {
            confirm: 'Pokračovat',
            back: 'Zpět'
          },
          toggle: {
            pop: 'Mám tarif se smlouvou',
            prp: 'Mám předplacenou GO kartu'
          },
          ribbon: {
            password: 'Nepamatuji si heslo',
            forgotEmail: 'Nepamatuji si přihlašovací email',
            createLogin: 'Vytvořit přihlašovací údaje',
            forgotLogin: 'Nepamatuji si přihlašovací údaje'
          },
          createCredentials: {
            createLoginTitle: 'Vytvoření přihlašovacích údajů',
            forgotLoginTitle: 'Nepamatuji si přihlašovací údaje',
            safeData: 'Vaše data jsou u nás v bezpečí',
            safeDataTooltip:
              'Nemusíte mít obavy, na bezpečnost u nás klademe nejvyšší důraz. Při ověřování dokladu totožnosti přes databázi MVČR i při odesílání údajů do Moje O2 je vše zašifrované a zabezpečené. Váš doklad nezůstane uložený ani v aplikaci nebo v telefonu. nemusíte se proto bát, že by se dostal do nepovolaných rukou.',
            setPassword: 'Nastavíte si heslo',
            continueWithQr: {
              first: 'Pokračujte',
              second: 'pomocí QR kódu nebo odkazu',
              third: 'do mobilní aplikace Moje O2'
            },
            inApp: {
              first: 'V mobilní aplikaci',
              second: 'si nastavíte e-mail a vyfotíte občanský průkaz'
            },
            scanQr: 'Načtěte QR kód',
            howTo: 'Jak na to?',
            howToTooltip: {
              first: 'Spusťte ve vašem mobilním telefonu aplikaci fotoaparátu nebo aplikaci pro načtení QR kódů.',
              second: 'Namiřte fotoaparát na QR kód a vyčkejte.',
              third: 'Následně dojde ke stažení nebo spuštění mobilní aplikace Moje O2 ve vašem mobilním telefonu.'
            },
            inMobile: 'Jste na mobilním zařízení?',
            mobileNavigate: 'Použijte tento odkaz'
          }
        },
        followEmail: {
          header: 'Na e-mailovou adresu {{email}} jsme vám odeslali odkaz k potvrzení.',
          descriptionReq:
            'Na vaši e-mailovou adresu jsme vám zaslali odkaz. Po jeho potvrzení se jednoduše přihlásíte a můžete vše začít pohodlně spravovat on-line.',
          description: 'Nyní stačí kliknout na odkaz, který jsme vám zasílali na e-mail ',
          linkReq: 'Zpět na přihlášení'
        }
      },
      kahl: {
        default: {
          success: 'Úspešně provedeno',
          danger: 'Obecná chyba'
        }
      },
      changeName: {
        title: 'Změna jména',
        firstName: 'Jméno',
        middleName: 'Prostřední jméno',
        lastName: 'Příjmení',
        action: {
          confirm: 'Uložit'
        },
        linkBack: 'Zrušit'
      },
      changeContactPhone: {
        title: 'Změna kontaktního telefonního čísla',
        description:
          'Na kontaktní číslo vám posíláme oznámení a upozornění. Když si číslo změníte, budeme vám jej posílat na nové číslo. Můžete zadat pouze české mobilní telefonní číslo.',
        contactPhone: 'Nové číslo',
        action: {
          confirm: 'Uložit'
        },
        linkBack: 'Zrušit'
      },
      changeEmail: {
        title: 'Změna emailu',
        email: 'Nový email (o2 id)',
        emailAgain: 'Nový email (o2 id) znovu',
        password: 'Stávající heslo pro Moje O2',
        showPassword: 'Zobrazit heslo',
        emailAgainNotSame: 'Hodnota se neshoduje',
        passwordError: 'Nepodařilo se Váš ověřit.',
        action: {
          confirm: 'Uložit'
        },
        linkBack: 'Zrušit'
      },
      header: {
        services: 'Služby',
        invoices: 'Vyučtování',
        documents: 'Documents',
        basket: 'Košík',
        o2: 'O2.cz',
        customer: 'Zákazník:',
        login: 'Přihlásit se',
        o2id: 'O2ID',
        info: {
          customer: 'Zákazník',
          home: 'Moje služby',
          settings: 'Nastavení',
          logout: 'Odhlásit',
          billing: 'Zobrazit hromadné vyúčtování'
        }
      },
      footer: {
        careAndSupport: 'Péče a podpora',
        privacy: 'Soukromí',
        about: 'O nás',
        contacts: 'Kontakty',
        newsInMyO2: 'Novinky v Moje O2',
        downloadMyO2: 'Stáhnout aplikaci Moje O2',
        languageToSwitch: 'English',
        cookies: 'Cookie',
        links: {
          careAndSupport: 'https://www.o2.cz/osobni/podpora',
          privacy: 'https://www.o2.cz/osobni/soukromi',
          about: 'https://www.o2.cz/spolecnost/',
          contacts: 'https://www.o2.cz/osobni/kontakty/',
          newsInMyO2: 'https://www.o2.cz/firmy-a-organizace/podpora/zmeny-v-moje-o2',
          downloadMyO2: 'https://www.o2knihovna.cz/aplikace/mojeo2#_il=mojeo2-footerlink'
        }
      }
    }
  }
};
