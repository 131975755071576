import { O2Indent, O2InfoMessage, O2Section } from 'o2-theme-react';
import React, { FC, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import useAnalytics from '../../hooks/useAnalytics';

//SCR11
const EmailSendInformation: FC = () => {
  const { pageLoaded } = useAnalytics();

  useEffect(() => {
    pageLoaded('2.6.2.1', I18n.t('ume.ext.postpaid.registration.email.sent'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <O2Section>
      <O2Indent>
        <O2InfoMessage color="good">{I18n.t('ume.ext.postpaid.registration.email.sent')}</O2InfoMessage>
      </O2Indent>
    </O2Section>
  );
};

export default EmailSendInformation;
