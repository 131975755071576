import { History } from 'history';
import {
  O2Button,
  O2Indent,
  O2Section,
  O2TextField,
  O2Tabs,
  O2TabsItem,
  O2TabsContent,
  O2TabsPane,
  O2ButtonGroup,
  O2UncontrolledTooltip,
  O2Pane,
  O2Flag,
  O2FlagPane,
  O2FlagContent
} from 'o2-theme-react';
import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import * as ContactPersonActions from '../../redux/actions/contactPersonActions';
import { emailIsValid } from '../../utils';
import { getApiPath } from '../../api/request-utils';
import '../../resources/recover.css';
import ShieldLock from '../../assets/shield-lock-outline.svg';
import QRplaceholder from '../../assets/qr-placeholder.png';
import useAnalytics from '../../hooks/useAnalytics';

interface DispatchProps {
  resetPasswordRequest: (email: string, history: History, world: string) => void;
}

interface Props extends DispatchProps {}
//SCR06
const RecoverPage: React.FC<Props> = ({ resetPasswordRequest }) => {
  const qrCodeUrl = decodeURIComponent((window as any).g_qrCodeUrl);
  const mobileNavigateUrl = (window as any).g_mobileNavigateUrl;
  const [emailToRecover, setEmailToRecover] = useState<string>('');
  const [emailToRecoverAgain, setEmailToRecoverAgain] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [emailAgainError, setEmailAgainError] = useState<string>('');
  const history = useHistory();
  const location = useLocation();
  const [customerType, setCustomerType] = useState<string>('');
  const [isPRP, setIsPRP] = useState<boolean>(false);
  const { pageLoaded, setFormSubmit } = useAnalytics();
  const [isResetPassword, setIsResetPassword] = useState<boolean>(true);
  const [isRegistration, setIsRegistration] = useState<boolean>(false);

  const session = useSelector<any, any>((state) => state.session);

  useEffect(() => {
    pageLoaded('2.6.3', I18n.t('ume.ext.requestPasswordReset.start.title'));
    const { world, resetPassword, registration, email } = queryString.parse(location.search);

    if (email) {
      setEmailToRecover(email as string);
      setEmailToRecoverAgain(email as string);
    }

    if (world && (world === 'O2PRP' || world === 'O2POP')) {
      setCustomerType(world);
      if (world === 'O2PRP') {
        setIsPRP(true);
      }
    }

    if (resetPassword === 'true' || resetPassword === undefined) setIsResetPassword(true);
    if (resetPassword === 'false') setIsResetPassword(false);

    if (registration === 'false' || registration === undefined) setIsRegistration(false);
    if (registration === 'true') setIsRegistration(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session.userInfo.logged) {
      if (session.userInfo.subscriberType === 'O2PRP') {
        window.location.href = 'http://pwsc-a-moje.o2.cz/dashboard';
      } else if (session.userInfo.subscriberType === 'O2POP') {
        window.location.href = 'https://moje.o2.cz/web/o2/userdashboard';
      }
    }
  }, [session]);

  useEffect(() => {
    if (emailToRecover.length > 0 && !emailIsValid(emailToRecover)) {
      setEmailError(I18n.t('ume.ext.registration.start.email.error'));
    } else {
      setEmailError('');
    }
  }, [emailToRecover]);

  useEffect(() => {
    isEmailsEqual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailToRecoverAgain, emailToRecover]);

  const isEmailsEqual = () => {
    if (emailToRecover === '' || emailToRecoverAgain === '' || emailToRecover === emailToRecoverAgain) {
      setEmailAgainError('');
    } else {
      setEmailAgainError(I18n.t('ume.ext.requestPasswordReset.start.emailRepeat.notSame'));
    }
  };

  const handleKeySubmitRecover = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const solveTheWorld = (): string => {
    if (customerType && (customerType === 'O2PRP' || customerType === 'O2POP')) {
      return customerType;
    } else {
      if (isPRP) {
        return 'O2PRP';
      } else {
        return 'O2POP';
      }
    }
  };

  const handleSubmit = () => {
    if (!emailIsValid(emailToRecover)) {
      setEmailError(I18n.t('ume.ext.registration.start.email.error'));
    } else {
      setEmailError('');
      setFormSubmit('2.6.3');
      resetPasswordRequest(emailToRecover, history, solveTheWorld());
    }
  };

  const activeStylesResolver = (isPRPBtn: boolean) => {
    if (isPRPBtn) {
      if (isPRP) {
        return 'o2login2-tabs__item o2login2-tabs__item--active';
      } else {
        return 'o2login2-tabs__item';
      }
    } else {
      if (isPRP) {
        return 'o2login2-tabs__item';
      } else {
        return 'o2login2-tabs__item o2login2-tabs__item--active';
      }
    }
  };

  return (
    <O2Section>
      {!isRegistration && (
        <O2Indent>
          <O2Tabs>
            <O2TabsItem
              className='o2-typo--heading-h3'
              active={isResetPassword === true}
              onClick={() => setIsResetPassword(true)}
            >
              {I18n.t('ume.ext.requestPasswordReset.start.ribbon.password')}
            </O2TabsItem>
            <O2TabsItem
              className='o2-typo--heading-h3'
              active={isResetPassword === false}
              onClick={() => setIsResetPassword(false)}
            >
              {customerType
                ? I18n.t('ume.ext.requestPasswordReset.start.ribbon.forgotEmail')
                : I18n.t('ume.ext.requestPasswordReset.start.ribbon.forgotLogin')}
            </O2TabsItem>
          </O2Tabs>
        </O2Indent>
      )}
      <O2Indent>
        <O2TabsContent activeTab={isResetPassword}>
          <O2TabsPane tabId={true}>
            <O2Indent>
              <p>{I18n.t('ume.ext.requestPasswordReset.start.description')}</p>
            </O2Indent>
            {customerType === '' && (
              <O2Indent>
                <div className='o2login2-tabs'>
                  <a href='#smlouva' className={activeStylesResolver(false)} onClick={(e) => setIsPRP(false)}>
                    <span className='o2login2-tabs__text'>
                      {I18n.t('ume.ext.requestPasswordReset.start.toggle.pop')}
                    </span>
                  </a>
                  <a href='#goKarta' className={activeStylesResolver(true)} onClick={(e) => setIsPRP(true)}>
                    <span className='o2login2-tabs__text'>
                      {I18n.t('ume.ext.requestPasswordReset.start.toggle.prp')}
                    </span>
                  </a>
                </div>
              </O2Indent>
            )}
            <O2Indent top={'standalone'}>
              <O2TextField
                wrapperClassName='o2-typo__wrapper'
                inline
                controlSize='large'
                label={I18n.t('ume.ext.requestPasswordReset.start.email.label')}
                data-test-id='recover-email'
                value={emailToRecover}
                onChange={(e) => setEmailToRecover(e.currentTarget.value)}
                onKeyDown={handleKeySubmitRecover}
                validationText={emailError}
                validationType={emailError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent>
              <O2TextField
                wrapperClassName='o2-typo__wrapper'
                inline
                controlSize='large'
                label={I18n.t('ume.ext.requestPasswordReset.start.emailRepeat.label')}
                data-test-id='recover-email-again'
                value={emailToRecoverAgain}
                onChange={(e) => {
                  setEmailToRecoverAgain(e.currentTarget.value);
                }}
                onKeyDown={handleKeySubmitRecover}
                validationText={emailAgainError}
                validationType={emailAgainError ? 'error' : undefined}
              />
            </O2Indent>
            <O2Indent top={'standalone'}>
              <O2ButtonGroup>
                <O2Button
                  data-test-id='recover-submit'
                  color='primary'
                  indentRight
                  onClick={handleSubmit}
                  disabled={
                    emailError !== '' ||
                    emailAgainError !== '' ||
                    emailToRecover === '' ||
                    emailToRecoverAgain === '' ||
                    emailToRecover !== emailToRecoverAgain
                  }
                >
                  {I18n.t('ume.ext.requestPasswordReset.start.action.confirm')}
                </O2Button>
                <O2Button
                  color='transparent'
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = getApiPath('auth/casLogin');
                  }}
                >
                  {I18n.t('ume.ext.requestPasswordReset.start.action.back')}
                </O2Button>
              </O2ButtonGroup>
            </O2Indent>
          </O2TabsPane>
          <O2TabsPane tabId={false}>
            {isRegistration && (
              <O2Indent>
                <h2 className='o2-typo__heading'>
                  {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.createLoginTitle')}
                </h2>
              </O2Indent>
            )}
            <O2Indent top={'standalone'}>
              <ol>
                <li className='o2-indent o2-indent--close-top'>
                  {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.continueWithQr.first')}
                  {" "}
                  <strong>
                    {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.continueWithQr.second')}
                  </strong>
                  {" "}
                  {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.continueWithQr.third')}
                </li>
                <li className='o2-indent o2-indent--close-top'>
                  <div>
                    <strong>{I18n.t('ume.ext.requestPasswordReset.start.createCredentials.inApp.first')}</strong>
                    {" "}
                    {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.inApp.second')}
                  </div>
                  <div>
                    <img src={ShieldLock} alt='' />
                    <span className='o2-text-content o2-typo--link o2-typo--indent-left-close' id={'id-data-safe'}>
                      {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.safeData')}
                    </span>
                    <O2UncontrolledTooltip target={'id-data-safe'}>
                      {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.safeDataTooltip')}
                    </O2UncontrolledTooltip>
                  </div>
                </li>
                <li className='o2-indent o2-indent--close-top'>
                  <div className='o2-indent'>
                    {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.setPassword')}
                  </div>
                </li>
              </ol>
            </O2Indent>
            <O2Indent top={'standalone'}>
              <O2Pane color='white' noBorder>
                <O2Flag>
                  <O2FlagPane indent>
                    <img src={qrCodeUrl ? qrCodeUrl : QRplaceholder} alt='' className='o2-imagesize__small' />
                  </O2FlagPane>
                  <O2FlagContent className='o2-typo__color-secondary'>
                    <h3 className='o2-indent'>
                      {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.scanQr')}
                      {" "}
                      <span className='o2-typo--link' id={'id-how-to'}>
                        {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.howTo')}
                      </span>
                      <O2UncontrolledTooltip style={{ textAlign: 'left' }} target={'id-how-to'}>
                        <ol>
                          <li>{I18n.t('ume.ext.requestPasswordReset.start.createCredentials.howToTooltip.first')}</li>
                          <li>{I18n.t('ume.ext.requestPasswordReset.start.createCredentials.howToTooltip.second')}</li>
                          <li>{I18n.t('ume.ext.requestPasswordReset.start.createCredentials.howToTooltip.third')}</li>
                        </ol>
                      </O2UncontrolledTooltip>
                    </h3>
                    <div className='o2-indent'>
                      <span>{I18n.t('ume.ext.requestPasswordReset.start.createCredentials.inMobile')}</span>
                      {" "}
                      <a href={mobileNavigateUrl} className='o2-typo--link'>
                        {I18n.t('ume.ext.requestPasswordReset.start.createCredentials.mobileNavigate')}
                      </a>
                    </div>
                  </O2FlagContent>
                </O2Flag>
              </O2Pane>
            </O2Indent>
            <O2Indent top={'standalone'}>
              <O2ButtonGroup>
                <O2Button
                  color='transparent'
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = getApiPath('auth/casLogin');
                  }}
                >
                  {I18n.t('ume.ext.requestPasswordReset.start.action.back')}
                </O2Button>
              </O2ButtonGroup>
            </O2Indent>
          </O2TabsPane>
        </O2TabsContent>
      </O2Indent>
    </O2Section>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  resetPasswordRequest: (email: string, history: History, world: string) =>
    dispatch(ContactPersonActions.resetPasswordRequest(email, history, world))
});

export default connect(null, mapDispatchToProps)(RecoverPage);
