import { History } from 'history';
import { Dispatch } from 'redux';

import DeviceApi from '../../api/device-api';
import { checkSuccessKahlMessages, checkDangerKahlMessages, redirectToStandaloneKahl } from '../../api/request-utils';

export type Action =
  | {
      type: 'MSISDN_VERIFICATION_START';
    }
  | {
      type: 'MSISDN_VERIFICATION_SUCCESS';
    }
  | {
      type: 'MSISDN_VERIFICATION_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'MSISDN_VERIFICATION_FINISH_START';
    }
  | {
      type: 'MSISDN_VERIFICATION_FINISH_SUCCESS';
    }
  | {
      type: 'MSISDN_VERIFICATION_FINISH_FAIL';
      error: string;
      code?: number;
    }
  | {
    type: 'PUK_MSISDN_VERIFICATION_FINISH_START';
    }
  | {
      type: 'PUK_MSISDN_VERIFICATION_FINISH_SUCCESS';
    }
  | {
      type: 'PUK_MSISDN_VERIFICATION_FINISH_FAIL';
      error: string;
      code?: number;
    };

export const assignMsisdn = (msisdn: string, history: History) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'MSISDN_VERIFICATION_START'
    });
    return DeviceApi.assignCreate(msisdn)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'MSISDN_VERIFICATION_SUCCESS'
        });
        history.push(`/ume/verify-code/${msisdn}`);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'MSISDN_VERIFICATION_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const assignFinishMsisdn = (msisdn: string, otp: string, history: History) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'MSISDN_VERIFICATION_FINISH_START'
    });
    return DeviceApi.assignFinish(msisdn, otp)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'MSISDN_VERIFICATION_FINISH_SUCCESS'
        });
        window.location.replace(res.data);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'MSISDN_VERIFICATION_FINISH_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const assignFinishPUK = (msisdn: string, puk: string, history: History) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'PUK_MSISDN_VERIFICATION_FINISH_START'
    });
    return DeviceApi.assignFinishPUK(msisdn, puk)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'PUK_MSISDN_VERIFICATION_FINISH_SUCCESS'
        });
        window.location.replace(res.data);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'PUK_MSISDN_VERIFICATION_FINISH_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};
