import { Action } from '../actions/preOTPSpaceActions';
import { PreOTPSpace } from './types';


const initialState: PreOTPSpace = {
  newPassword: null,
  oldPassword: null
};

export default (state: PreOTPSpace = initialState, action: Action): PreOTPSpace => {
  switch (action.type) {
    case 'NEW_PASSWORD':
      return {
        newPassword: action.newPassword,
        oldPassword: null
      };
    case 'CHANGE_PASSWORD':
      return {
        newPassword: action.newPassword,
        oldPassword: action.oldPassword
      };
    default:
      return state;
  }
};
