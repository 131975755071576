// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useSelector } from 'react-redux';
import SharedObjectApi from '../../api/shared-objects-api';

const Footer: React.FC = () => {
  const [ locale, setLocale ] = useState<string>('');
  const sharedObjectSession = useSelector<any, any>(
    state => state.sharedObjectSession
  );

  useEffect(() => {
    if (sharedObjectSession) {
      setLocale(sharedObjectSession.sharedObjectSession.generalLoginData.userPreferences.locale);
    }
  }, [ sharedObjectSession ])

  
  const openCookieSetting = () => {
    if (typeof __cmp === 'function') {
      __cmp('showGDPRScreenAdvanced');
    }
  };

  return (
    <div className="o2-footer">
      <div className="o2-footer__width">
        <ul className="o2-footer__menu">
          <li className="o2-footer__menu-item">
          <a href="/" className="o2-footer__link" onClick={(e) => {
              e.preventDefault();
                SharedObjectApi.patchShareObjectPreferencesLanguage(locale === '' ? 'en_US' : ((locale === 'cs' || locale === 'cs_CZ') ? 'en_US' : 'cs_CZ')).then(res => {
                  document.location.reload(true);
                }).catch(err => {
                  console.log(err);
                })
            }}>{I18n.t('ume.ext.footer.languageToSwitch')}</a>
          </li>
          <li className="o2-footer__menu-item"><a href={I18n.t('ume.ext.footer.links.careAndSupport')} className="o2-footer__link">{I18n.t('ume.ext.footer.careAndSupport')}</a></li>
          <li className="o2-footer__menu-item"><a href={I18n.t('ume.ext.footer.links.privacy')} className="o2-footer__link">{I18n.t('ume.ext.footer.privacy')}</a></li>
          <li className="o2-footer__menu-item"><a href={I18n.t('ume.ext.footer.links.about')} className="o2-footer__link">{I18n.t('ume.ext.footer.about')}</a></li>
          <li className="o2-footer__menu-item"><a href={I18n.t('ume.ext.footer.links.contacts')} className="o2-footer__link">{I18n.t('ume.ext.footer.contacts')}</a></li>
          <li className="o2-footer__menu-item"><a href={I18n.t('ume.ext.footer.links.newsInMyO2')} className="o2-footer__link">{I18n.t('ume.ext.footer.newsInMyO2')}</a></li>
          <li className="o2-footer__menu-item"><a href={I18n.t('ume.ext.footer.links.downloadMyO2')} className="o2-footer__link">{I18n.t('ume.ext.footer.downloadMyO2')}</a></li>
          <li className="o2-footer__menu-item"><a href="/" onClick={openCookieSetting} className="o2-footer__link">{I18n.t('pwsc.footer.cookies')}</a></li>
        </ul>
        <div className="o2-footer__copy">&#169; O2 Czech Republic a.s.</div>
      </div>
    </div>
  );
};

export default Footer;
