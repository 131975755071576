import { Action } from '../actions/contactPersonActions';
import { Registration } from './types';

const initialState: Registration = {
  processing: false,
  registered: false,
  passwordChanged: false
};

export default (state: Registration = initialState, action: Action): Registration => {
  switch (action.type) {
    case 'REGISTRATION_START':
      return {
        ...state,
        error: undefined,
        processing: true,
        registered: false
      };
    case 'REGISTRATION_SUCCESS':
      return {
        ...state,
        processing: false,
        registered: true
      };
    case 'REGISTRATION_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error
      };
    case 'CHANGE_PASSWORD_START':
      return {
        ...state,
        error: undefined,
        processing: true,
        passwordChanged: false
      };
    case 'CHANGE_PASSWORD_SUCCESS':
      return {
        ...state,
        processing: false,
        passwordChanged: true
      };
    case 'CHANGE_PASSWORD_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error
      };
    default:
      return state;
  }
};
