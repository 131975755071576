import { History } from 'history';
import { O2Button, O2Indent, O2Section, O2TextField, O2Icon, O2Radio, O2ControlWrapper } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import * as DeviceActions from '../../redux/actions/deviceActions';
import * as SessionActions from '../../redux/actions/sessionActions';
import { Session } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import HeadingWithInfo from '../common/HeadingWithInfo';
import { isPhoneValid, isPukValid } from '../../utils';
import useAnalytics from '../../hooks/useAnalytics';

interface StateProps {
  session: Session;
}

interface DispatchProps {
  assignMsisdn: (msisdn: string, history: History) => void;
  assignFinishPUK: (msisdn: string, puk: string, history: History) => void;
  getUserInfo: () => void;
}

interface Props extends StateProps, DispatchProps {}
//SCR02
const VerifyPage: React.FC<Props> = ({ getUserInfo, session, assignMsisdn, assignFinishPUK }) => {
  const [msisdn, setMsisdn] = useState<string>('');
  const [msisdnError, setMsisdnError] = useState<string>('');
  const [puk, setPuk] = useState<string>('');
  const [pukError, setPukError] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<'OTP' | 'PUK'>('OTP');
  const { pageLoaded, setFormSubmit } = useAnalytics();

  const history = useHistory();

  useEffect(() => {
    getUserInfo();
    pageLoaded('666.1.10.3', I18n.t('ume.ext.registration.start.contactValidateFail'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeySubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && msisdn.length > 0 && msisdnError === '') {
      submitHandle();
    }
  };

  const handleMsisdnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setMsisdnError('');

    if (!isPhoneValid(value)) {
      setMsisdnError(I18n.t('ume.ext.registration.start.phone.notNumber'));
    }

    setMsisdn(value);
  };

  const handlePukChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setPukError('');

    if (!isPukValid(value)) {
      setPukError(I18n.t('ume.ext.pairingNumber.PUK.format'));
    }

    setPuk(value);
  };

  const submitHandlePUK = () => {
    setFormSubmit('666.1.10.3');
    assignFinishPUK(msisdn, puk, history);
  }

  const submitHandle = () => {
    setFormSubmit('666.1.10.3');
    assignMsisdn(msisdn, history);
  };

  const handleRadioChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  if (session.processing) {
    return <HeadingWithInfo headingText={I18n.t('ume.ext.pairingNumber.loading')} />;
  } else if (session.notLogged) {
    return <HeadingWithInfo headingText={I18n.t('ume.ext.pairingNumber.notLogged')} />;
  } else if (
    session.notLogged !== undefined &&
    session.notLogged === false &&
    session.personValidForPairing !== undefined &&
    session.personValidForPairing === false
  ) {
    return (
      <O2Section>
        <O2Indent>
          <h1 className='o2-typo__heading'>{I18n.t('ume.ext.registration.start.contactValidateFail')}</h1>
        </O2Indent>
        <O2Indent>
        {I18n.t('ume.ext.registration.start.alreadyPaired')} {session.userInfo.msisdn}
          <O2Button data-test-id='verify-back' color='transparent' onClick={() => window.location.replace(session.ATGLink)}>
            {I18n.t('ume.ext.recover.back')}
          </O2Button>
        </O2Indent>
      </O2Section>
    );
  } else {
    return (
      <O2Section>
        <O2Indent>
          <h1 className='o2-typo__heading'>{I18n.t('ume.ext.pairingNumber.header')}</h1>
        </O2Indent>
        <O2Indent>
          <O2Icon name="info-fill" title={I18n.t('ume.ext.pairingNumber.msisdn.tooltip')} style={{ position: 'relative', top: '54px', left: '274px' }} />
          <br />
          <O2TextField
            inline
            indentRight
            data-test-id='verify-msisdn'
            value={msisdn}
            type='text'
            onChange={handleMsisdnChange}
            onKeyDown={handleKeySubmit}
            label={I18n.t('ume.ext.pairingNumber.msisdn.label')}
            note={I18n.t('ume.ext.pairingNumber.msisdn.hint')}
            controlSize='medium'
            validationText={msisdnError}
            validationType={msisdnError ? 'error' : undefined}
          />
        </O2Indent>
        <O2Indent>
          {/* 010 */}
          <O2Indent>
            <div className="o2-control__label">{I18n.t('ume.ext.pairingNumber.method.label')}</div>
          </O2Indent>
          {/* 011 */}
          <O2Indent>
            <O2Radio
              id="o2-1-10-3_011"
              name="o2-1-10-3_verification-method"
              value="OTP"
              checked={selectedOption === 'OTP'}
              label={I18n.t('ume.ext.pairingNumber.method.OTP')}
              onChange={handleRadioChange}
            />
          </O2Indent>
          {/* 012 */}
          <O2Indent top="close">
            <O2Radio
              id="o2-1-10-3_012"
              name="o2-1-10-3_verification-method"
              value="PUK"
              checked={selectedOption === 'PUK'}
              label={I18n.t('ume.ext.pairingNumber.method.PUK')}
              onChange={handleRadioChange}
            />
          </O2Indent>
        </O2Indent>
        {selectedOption === 'PUK' && (
          <>
            <O2Indent>
              {/* 013 */}
              <O2ControlWrapper indentLeft style={{paddingLeft: '28px'}}>
                <O2TextField
                  label={I18n.t('ume.ext.pairingNumber.PUK.label')}
                  controlSize="medium"
                  mobileFull
                  inline="md"
                  value={puk}
                  type='text'
                  onChange={handlePukChange}
                  validationText={pukError}
                  validationType={pukError ? 'error' : undefined}
                />
              </O2ControlWrapper>
            </O2Indent>
            <O2Indent>
              {/* 005 */}
              <O2Button
                color="primary"
                fullWidth="xs"
                disabled={
                  (puk.length > 0 && pukError === '' ? false : true) ||
                  (msisdn.length > 0 && msisdnError === '' ? false : true)
                }
                onClick={submitHandlePUK}
              >
                {I18n.t('ume.ext.pairingNumber.PUK.submit')}
              </O2Button>
            </O2Indent>
          </>
        )}
        {selectedOption === 'OTP' && (
          <O2Indent standalone>
            <O2Button
              data-test-id='verify-submit'
              color='primary'
              disabled={msisdn.length > 0 && msisdnError === '' ? false : true}
              onClick={submitHandle}
            >
              {I18n.t('ume.ext.pairingNumber.action.sendOTP')}
            </O2Button>
          </O2Indent>
        )}
      </O2Section>
    );
  }
};

const mapStateToProps = (state: StoreState): StateProps => ({
  session: state.session
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  assignMsisdn: (msisdn: string, history: History) => dispatch(DeviceActions.assignMsisdn(msisdn, history)),
  assignFinishPUK: (msisdn: string, puk: string, history: History) => dispatch(DeviceActions.assignFinishPUK(msisdn, puk, history)),
  getUserInfo: () => dispatch(SessionActions.loadUserInfo(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage);
