import { O2Indent, O2InfoMessage, O2Section, O2Modal, O2ModalBody, O2ModalFooter, O2ModalFooterButton, O2TextField, O2Button } from 'o2-theme-react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import React, { FC, useState, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import { ThunkDispatch } from 'redux-thunk';
import { useHistory } from 'react-router-dom';
import { sendOtp, verify } from '../../redux/actions/verificationActions';
import { useLocation } from 'react-router';
import { PreOTPSpace, Verification } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { getApiPath, redirectToStandaloneKahl } from '../../api/request-utils';
import * as ContactPersonAction from '../../redux/actions/contactPersonActions';
import * as PasswordAction from '../../redux/actions/passwordActions';
import * as RegistrationActions from '../../redux/actions/contactPersonActions';
import useAnalytics from '../../hooks/useAnalytics';

interface StateProps {
  verification: Verification;
  preOTPSpace: PreOTPSpace;
}

interface DispatchProps {
  verifySecret: (secret: string, acceptOnly: string[]) => void;
  sendOtp: (verificationSecret: string, setSending: any) => void;
  createPassword: (password: string, verificationSecret: string, otp?: string) => void;
  confirmEmailChange: (password: string, verificationSecret: string, otp?: string) => void;
  resetPassword: (verificationToken: string, newPassword: string, otp?: string) => void;
  changePassword: (oldPassword: string, newPassword: string, otp?: string) => void;
}

interface Props extends StateProps, DispatchProps {}
//SCR09
const OTPHighSecurity: FC<Props> = ({ 
  verifySecret,
  sendOtp,
  createPassword,
  confirmEmailChange,
  resetPassword,
  changePassword,
  verification,
  preOTPSpace
}) => {
  const [verSecret, setVerSecret] = useState<string>('');
  const [ openModal, setOpenModal ] = useState<boolean>(false);
  const [ OTP, setOTP ] = useState<string>('');
  const [ sending , setSending ] = useState<boolean>(false);
  const location = useLocation();
  const { target } = queryString.parse(location.search);
  const history = useHistory();
  const { pageLoaded, setFormSubmit } = useAnalytics();

  const verifyAndAction = () => {
    setFormSubmit('2.6.3.4');
    switch (target) {
      case 'NEW_CONTACT_PERSON':
        return createPassword(preOTPSpace.newPassword ? preOTPSpace.newPassword : "", verSecret, OTP);
      case 'EMAIL_CHANGE':
        return confirmEmailChange(preOTPSpace.newPassword ? preOTPSpace.newPassword : "", verSecret, OTP);
      case 'PASSWORD_CHANGE':
      case 'PASSWORD_RESET':
        return resetPassword(verSecret, preOTPSpace.newPassword ? preOTPSpace.newPassword : "", OTP);
      case 'NEW_PASSWORD_CHANGE':
        return changePassword(preOTPSpace.oldPassword ? preOTPSpace.oldPassword : "", preOTPSpace.newPassword ? preOTPSpace.newPassword : "", OTP);
    }
  }
  
  useEffect(() => {
    pageLoaded('2.6.3.4', I18n.t('ume.ext.enhancedSecurity.otp.otpSend'));
    const { secret } = queryString.parse(location.search);
    if (preOTPSpace.newPassword === "" || preOTPSpace.newPassword === null) {
      switch (target) {
        case 'NEW_PASSWORD_CHANGE':
            history.push(`/ume/password?secret=${secret}`);
          break;
        case 'NEW_CONTACT_PERSON':
        case 'EMAIL_CHANGE':
        case 'PASSWORD_CHANGE':
        case 'PASSWORD_RESET':
            history.push(`/ume/new-password?secret=${secret}`);
          break;
      }
    }
    
    if (secret) {
      verifySecret(secret as string, ['NEW_CONTACT_PERSON', 'EMAIL_CHANGE', 'PASSWORD_CHANGE', 'PASSWORD_RESET', 'NEW_PASSWORD_CHANGE']);
      setVerSecret(secret as string);
      sendOtp(secret as string, setSending);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (verification && (verification.emailType?.contactPhone === undefined || verification.emailType?.contactPhone === null)) {
      redirectToStandaloneKahl('ID1750', 'UME_EXT');
    }
  }, [ verification ]);

  const openChat = () => {
    (window as any).postMessage({
      content: {
        reason: 'ManuallyCalled',
      },
      operation: 'RequestWidgetOpenWindow'
    });
      
    switch (target) {
      case 'NEW_CONTACT_PERSON':
      case 'EMAIL_CHANGE':
      case 'NEW_PASSWORD_CHANGE':
        (window as any).postMessage({
          content: {
            'WDG_CONTACT_PHONE_CHANGE': true
          },
          operation: 'RequestWidgetSetCustomData'
        });
        break;
      case 'PASSWORD_CHANGE':
      case 'PASSWORD_RESET':
        (window as any).postMessage({
          content: {
            'WDG_CONTACT_PASS_FORGOTTEN': true
          },
          operation: 'RequestWidgetSetCustomData'
        });
        break;
    }
  }
  
  return (
    <>
      <O2Section>
        <O2Indent>
          <O2InfoMessage color="good">{I18n.t('ume.ext.enhancedSecurity.otp.otpSend')}</O2InfoMessage>
        </O2Indent>
        <O2Indent>
          {verification.emailType?.contactPhone &&
            <>
              {I18n.t('ume.ext.enhancedSecurity.otp.text')} <b>{verification.emailType && verification.emailType?.contactPhone && verification.emailType?.contactPhone.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')}.</b>
            </>
          }
          <br />
          <a href='#top' className="o2-typo__link o2-typo__link--reverted js-modal" onClick={() => setOpenModal(true)}>
            {I18n.t('ume.ext.enhancedSecurity.otp.contactPhone.text')}
          </a>
        </O2Indent>
        <O2Indent>
            <O2TextField
              inline
              indentRight
              data-test-id='register-first-name'
              label={I18n.t('ume.ext.enhancedSecurity.otp.input.label')}
              controlSize='medium'
              value={OTP}
              onChange={e => setOTP(e.currentTarget.value)}
            />
            {!sending && <a href='#top' className='js-notify' onClick={() => {
              sendOtp(verSecret, setSending)
              setSending(true)
            }}>
                {I18n.t('ume.ext.enhancedSecurity.otp.resend')}
              </a>
            }
            {sending && <span>{I18n.t('ume.ext.enhancedSecurity.otp.sending')}</span>}
          </O2Indent>
          <O2Indent standalone>
            <O2Button
              data-test-id='otp-submit'
              color='primary'
              indentRight
              type='submit'
              disabled={!OTP}
              onClick={() => verifyAndAction()}
            >
              {I18n.t('ume.ext.enhancedSecurity.otp.continue')}
            </O2Button>
            <O2Button
              data-test-id='register-submit'
              color='transparent'
              type='button'
              onClick={() => window.location.href = getApiPath('auth/casLogin')}
            >
              {I18n.t('ume.ext.enhancedSecurity.otp.cancel')}
            </O2Button>
          </O2Indent>
      </O2Section>
      <O2Modal
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
        backdrop
      >
        <O2ModalBody>
          <O2Indent>
            <h2>{I18n.t('ume.ext.enhancedSecurity.otp.contactPhone.instructions')}</h2>
          </O2Indent>
        </O2ModalBody>
        <O2ModalFooter>
          <O2ModalFooterButton color='success' onClick={() => setOpenModal(false)}>
            <span className="o2-icon o2-icon--checkmark"> </span>
            <span className="o2-modal__footer-btn-text">{I18n.t('ume.ext.enhancedSecurity.otp.contactPhone.close')}</span>
          </O2ModalFooterButton>
          <O2ModalFooterButton color='success' onClick={() => {
            openChat();
            setOpenModal(false);
          }}>
            <span className="o2-icon o2-icon--chat"> </span>
            <span className="o2-modal__footer-btn-text">{I18n.t('ume.ext.enhancedSecurity.otp.contactPhone.openChat')}</span>
          </O2ModalFooterButton>
        </O2ModalFooter>
      </O2Modal>
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  verification: state.verification,
  preOTPSpace: state.preOTPSpace
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  verifySecret: (verificationSecret: string, acceptOnly: string[]) => dispatch(verify(verificationSecret, acceptOnly)),
  sendOtp:  (verificationSecret: string, setSending: any) => dispatch(sendOtp(verificationSecret, setSending)),
  createPassword: (password: string, verificationSecret: string, otp?: string) =>
    dispatch(PasswordAction.createPassword(password, verificationSecret, otp)),
  confirmEmailChange: (password: string, verificationSecret: string, otp?: string) =>
    dispatch(ContactPersonAction.confirmEmailChange(password, verificationSecret, otp)),
  resetPassword: (verificationSecret: string, newPassword: string, otp?: string) =>
    dispatch(ContactPersonAction.resetPassword(verificationSecret, newPassword, otp)),
  changePassword: (oldPassword: string, newPassword: string, otp?: string) =>
    dispatch(RegistrationActions.changePassword(oldPassword, newPassword, otp)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OTPHighSecurity);