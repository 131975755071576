import { I18n } from 'react-i18nify-lite';
import { Dispatch } from 'redux';
import { History } from 'history';

import VerificationApi from '../../api/verificationApi';
import { addSimpleMessage } from '../../utils';
import { checkSuccessKahlMessages, checkDangerKahlMessages, redirectToStandaloneKahl } from '../../api/request-utils';
import { EmailType } from '../reducers/types';

export type Action =
  | {
      type: 'VERIFICATION_START';
    }
  | {
      type: 'VERIFICATION_SUCCESS';
      emailType: EmailType | null;
      valid: boolean;
    }
  | {
      type: 'VERIFICATION_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'RESEND_EMAIL_START';
    }
  | {
      type: 'RESEND_EMAIL_SUCCESS';
      token?: string;
    }
  | {
      type: 'RESEND_EMAIL_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'VERIFY_OTP_START';
    }
  | {
      type: 'VERIFY_OTP_SUCCESS';
      otpCode?: string;
    }
  | {
      type: 'VERIFY_OTP_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'SEND_OTP_START';
    }
  | {
      type: 'SEND_OTP_SUCCESS';
      token?: string;
    }
  | {
      type: 'SEND_OTP_FAIL';
      error: string;
      code?: number;
    };

export const verify = (verificationSecret: string, acceptOnly: string[], setInvalidSecret?: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'VERIFICATION_START'
    });

    return VerificationApi.verify(verificationSecret)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        const type = res.data;
        let valid = acceptOnly.includes(type.verificationEmailType);
        if (!valid) {
          checkDangerKahlMessages({resultCode: 'ID1304'});
          if (typeof setInvalidSecret === 'function') {
            setInvalidSecret(true)
          }
        }
        dispatch({
          type: 'VERIFICATION_SUCCESS',
          valid: valid,
          emailType: type
        });
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        
        if (typeof setInvalidSecret === 'function') {
          setInvalidSecret(true)
        }
        return dispatch({
          type: 'VERIFICATION_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const resendEmail = (resendSecret: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'RESEND_EMAIL_START'
    });

    return VerificationApi.resendEmail(resendSecret)
      .then(res => {
        addSimpleMessage(I18n.t('ume.ext.registration.followEmail.resendSuccess'), 'success');
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'RESEND_EMAIL_SUCCESS',
          token: res.data
        });
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        }else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'RESEND_EMAIL_FAIL',
          error: err.message.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const verifyOtp = (otp: string, pushTo: string, history: History, verificationSecret?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'VERIFY_OTP_START'
    });

    return VerificationApi.verifyOtp(otp, verificationSecret)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'VERIFY_OTP_SUCCESS',
          otpCode: otp
        });

        switch (pushTo) {
          case 'NEW_PASSWORD_CHANGE':
            history.push('/ume/password');
          break;
          case 'NEW_CONTACT_PERSON':
          case 'EMAIL_CHANGE':
          case 'PASSWORD_CHANGE':
          case 'PASSWORD_RESET':
            history.push(`/ume/new-password?secret=${verificationSecret}`);
          break;
        }
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'VERIFY_OTP_FAIL',
          error: err.message.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const sendOtp = (verificationSecret: string, setSending: any) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'SEND_OTP_START'
    });

    return VerificationApi.sendOtp(verificationSecret)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        setSending(false);
        dispatch({
          type: 'SEND_OTP_SUCCESS',
          token: res.data
        });
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          if (err.response.status !== 204 && err.response.data.resultCode) {
            checkDangerKahlMessages(err.response.data);
          }
        }
        setSending(false);
        return dispatch({
          type: 'SEND_OTP_FAIL',
          error: err.message.toString(),
          code: err.response && err.response.status
        });
      });
  };
};
