import { Action } from '../actions/deviceActions';
import { Device } from './types';

const initialState: Device = {
  processing: false
};

export default (state: Device = initialState, action: Action): Device => {
  switch (action.type) {
    case 'MSISDN_VERIFICATION_START':
      return {
        ...state,
        error: undefined,
        processing: true,
        send: undefined
      };
    case 'MSISDN_VERIFICATION_SUCCESS':
      return {
        ...state,
        processing: false,
        send: true
      };
    case 'MSISDN_VERIFICATION_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error
      };
    default:
      return state;
  }
};
