import { combineReducers } from 'redux';

import StoreState from '../store/storeState';
import registration from './contactPersonReducer';
import device from './deviceReducer';
import messages from './messageReducer'
import session from './sessionReducer';
import verification from './verificationReducer';
import kahlInfo from './kahlInfoReducer';
import updatingContactData from './contactPersonEditingReducer';
import sharedObjectSession from './sharedObjectsReducer';
import preOTPSpace from './preOTPSpaceReducer';

const rootReducer = combineReducers<StoreState>({
  session: session,
  messages: messages,
  registration: registration,
  verification: verification,
  device: device,
  kahlInfo: kahlInfo,
  updatingContactData: updatingContactData,
  sharedObjectSession: sharedObjectSession,
  preOTPSpace: preOTPSpace,
});

export default rootReducer;
