import { applyMiddleware, createStore, Store } from 'redux';
import * as Redux from 'redux';
import thunk, { ThunkDispatch } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers';
import StoreState from './storeState';

var store: Store<StoreState>;

export const initializeStore = () => {
  store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  return store;
};

export const getStore = () => {
  return store;
};

export const getState = (): StoreState => {
  return store.getState();
};

export const thunkDispatch = () => store.dispatch as ThunkDispatch<StoreState, void, Redux.AnyAction>;
