import { sendGet, sendPost, VERIFICATION_API } from './request-utils';

export default class VerificationApi {
  //TYPE OPTIONS == NEW_CONTACT_PERSON, NEW_XTV_CONTACT_PERSON, EMAIL_CHANGE, PASSWORD_CHANGE, NEW_POSTPAID_CONTACT_PERSON
  static verify(verificationSecret: string) {
    return sendGet(VERIFICATION_API + 'checkVerificationEmail', { verificationSecret: verificationSecret }, 'text');
  }

  static resendEmail(resendSecret: string) {
    return sendGet(VERIFICATION_API + 'resend', { resendSecret: resendSecret }, 'text');
  }

  static verifyOtp(otp: string, verificationSecret?: string) {
    return sendGet(VERIFICATION_API + 'verifyOtp', { otp, ...(verificationSecret && { verificationSecret })}, 'text');
  }

  static sendOtp(verificationSecret: string) {
    return sendPost(VERIFICATION_API + 'sendOTP', { verificationSecret: verificationSecret }, null);
  }
}
