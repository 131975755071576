import { Action } from '../actions/contactPersonEditingActions';
import { UpdatingContactData } from './types';

const intitialState: UpdatingContactData = {
  processing: false,
  phoneUpdated: false,
  nameUpdated: false,
  emailUpdated: false,
}

export default (state: UpdatingContactData = intitialState, action: Action): UpdatingContactData => {
  switch (action.type) {
    case 'CHANGE_CONTACT_PHONE_START':
      return {
        ...state,
        error: undefined,
        processing: true,
      }
    case 'CHANGE_CONTACT_PHONE_SUCCESS':
      return {
        ...state,
        error: undefined,
        processing: false,
        phoneUpdated: true,
      }
    case 'CHANGE_CONTACT_PHONE_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error,
      }
    case 'CHANGE_NAME_START':
      return {
        ...state,
        error: undefined,
        processing: true,
      }
    case 'CHANGE_NAME_SUCCESS':
      return {
        ...state,
        error: undefined,
        processing: false,
        nameUpdated: true,
      }
    case 'CHANGE_NAME_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error,
      }
    case 'CHANGE_EMAIL_START':
      return {
        ...state,
        error: undefined,
        processing: true,
      }
    case 'CHANGE_EMAIL_SUCCESS':
      return {
        ...state,
        error: undefined,
        processing: false,
        emailUpdated: true,
      }
    case 'CHANGE_EMAIL_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error,
      }
    case 'GET_CONTACT_PERSON_DATA_START':
      return {
        ...state,
        error: undefined,
        processing: true,
      }
    case 'GET_CONTACT_PERSON_DATA_SUCCESS':
      return {
        ...state,
        error: undefined,
        processing: false,
        data: action.data,
      }
    case 'GET_CONTACT_PERSON_DATA_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error,
      }
    default:
      return state;
  }
};