import React from 'react';
import { O2Section, O2Indent } from 'o2-theme-react';

interface Props {
  headingText: string,
  descriptionText?: string
}

const HeadingWithInfo: React.FC<Props> = ({ headingText, descriptionText }) => {
  return (
    <O2Section>
      <O2Indent>
        <h1 className='o2-typo__heading'>{headingText}</h1>
      </O2Indent>
      {descriptionText !== undefined ? <O2Indent>{descriptionText}</O2Indent> : ''}
    </O2Section>
  );
}

export default HeadingWithInfo;