import { COMMON_MANAGE_API, POSTPAID_MANAGE_API, PREPAID_MANAGE_API, sendGet, sendPost, sendPut } from './request-utils';
import { ContactPostpaidRegistration, ContactRegistration } from './types';

export default class ContactPersonApi {
  static register(registration: ContactRegistration) {
    return sendPost(PREPAID_MANAGE_API + 'register', null, registration);
  }

  static registerPostpaid(registration: ContactPostpaidRegistration) {
    return sendPost(POSTPAID_MANAGE_API + 'register', null, registration);
  }

  static createPassword(password: string, verificationSecret: string, otp?: string) {
    return sendPut<string>(COMMON_MANAGE_API + 'activate', { verificationSecret: verificationSecret }, { password: password, ...(otp && { otp }) });
  }

  static createPasswordPostpaid(password: string, verificationSecret: string) {
    return sendPut<string>(POSTPAID_MANAGE_API + 'activate', { verificationSecret: verificationSecret }, { password: password });
  }

  static changePassword(oldPassword: string, newPassword: string, otp?: string) {
    return sendPut(COMMON_MANAGE_API + 'password/change', null, {
      oldPassword: oldPassword,
      newPassword: newPassword,
      ...(otp && { otp })
    });
  }

  static resetPassword(verificationSecret: string, newPassword: string, otp?: string) {
    return sendPut(COMMON_MANAGE_API + 'password/reset', { verificationSecret: verificationSecret }, { password: newPassword, ...(otp && { otp }) });
  }

  static resetPasswordRequest(email: string, world: string) {
    return sendPut(COMMON_MANAGE_API + 'password/reset/request', { email: email, world: world }, null);
  }

  static validateDevice() {
    return sendGet(PREPAID_MANAGE_API + 'validate/device', null);
  }

  static validatePerson() {
    return sendPut<string>(PREPAID_MANAGE_API + 'validate/pairing', null, null);
  }

  static confirmEmailChange(password: string, verificationSecret: string, otp?: string) {
    return sendPut(
      COMMON_MANAGE_API + 'email/change/confirm',
      { verificationSecret: verificationSecret },
      { password: password, ...(otp && { otp }) }
    );
  }
}
