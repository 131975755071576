import { Action } from '../actions/verificationActions';
import { Verification } from './types';

const initialState: Verification = {
  processing: false,
  token: '',
  emailType: null,
  otpCode: '',
  valid: false,
};

export default (state: Verification = initialState, action: Action): Verification => {
  switch (action.type) {
    case 'VERIFICATION_START':
      return {
        ...state,
        error: undefined,
        processing: true,
        valid: false
      };
    case 'VERIFICATION_SUCCESS':
      return {
        ...state,
        processing: false,
        valid: action.valid,
        emailType: action.emailType
      };
    case 'VERIFICATION_FAIL':
      return {
        ...state,
        valid: false,
        processing: false,
        error: action.error
      };
    case 'RESEND_EMAIL_START':
      return {
        ...state,
        error: undefined,
        processing: true,
        valid: undefined
      };
    case 'RESEND_EMAIL_SUCCESS':
      return {
        ...state,
        processing: false,
        token: action.token
      };
    case 'RESEND_EMAIL_FAIL':
      return {
        ...state,
        valid: false,
        processing: false,
        error: action.error
      };
    case 'VERIFY_OTP_SUCCESS':
      return {
        ...state,
        otpCode: action.otpCode
      };
    default:
      return state;
  }
};
