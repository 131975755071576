import { I18n } from 'react-i18nify-lite';

import { COOKIE_NAME } from './constants/cookies';
import { addMessage } from './redux/actions/messageActions';
import { getStore } from './redux/store/store';

export const setCookie = (name: String, value: string, domain?: String) => {
  document.cookie = `${name}=${value}; path=/; ${domain ? `domain=${domain}` : ''}`;
};

export const getCookie = (name: String) => {
  const b = document.cookie.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)');
  return b ? b.pop() : '';
};

export const clearCookies = (name: String) => {
  document.cookie = COOKIE_NAME + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};

export const initializeSessionCookie = () => {
  const cookieNameFromConfig = (window as any).g_apfSessionCookieName;
  const cookieName =
    cookieNameFromConfig === null || cookieNameFromConfig === '' ? 'o2-session-id' : cookieNameFromConfig;

  if (!getCookie(cookieName)) {
    const apfSessionCookieDomain = (window as any).g_apfSessionCookieDomain;
    setCookie(cookieName, generateUUID(), apfSessionCookieDomain);
  }
};

export const generateUUID = () => {
  var d = performance.now(); // Timestamp
  var d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
    var r = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // Use timestamp until depleted
      // tslint:disable-next-line:no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // Use microseconds since page-load if supported
      // tslint:disable-next-line:no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // tslint:disable-next-line:no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const addSimpleMessage = (message: string, type: 'success' | 'warning' | 'danger') => {
  getStore().dispatch(addMessage(message, type));
};

export const emailIsValid = (email: string) => {
  //eslint-disable-next-line
  return /^(?=[a-z0-9@.!#$%&'*+\/=?^_`{|}~-]{6,254})(?=[a-z0-9.!#$%&'*+\/=?^_`{|}~-]{1,64}@)[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:(?=[a-z0-9-]{1,63}\.)[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?=[a-z0-9-]{1,63})[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
    email
  );
};

export const isPasswordValid = (passString: string): string => {
  const haveDeniedChars = (pass: string): boolean => {
    for (let i = 0; i < pass.length; i++) {
      // eslint-disable-next-line no-useless-escape
      let forbiddenChar = /^[^0-9a-zA-ZěščřžýáíéúóůňťďĚŠČŘŽÝÁÍÉÚŮŇŤĎ!@#\$%\^&\*()_\+,'\.\?\-=`\[\];\/]/.test(pass[i]);
      if (forbiddenChar) {
        return true;
      }
    }

    return false;
  };

  const isComplex = (password: string): boolean => {
    return /^((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=[\S]+$).{6,25})/.test(password);
  };

  if (passString.length < 6) {
    return I18n.t('ume.ext.changePassword.passwordMin');
  } else if (passString.length > 25) {
    return I18n.t('ume.ext.changePassword.passwordMax');
  } else if (haveDeniedChars(passString)) {
    return I18n.t('ume.ext.changePassword.passwordRules');
  } else if (!isComplex(passString)) {
    return I18n.t('ume.ext.changePassword.passwordComplex');
  } else {
    return '';
  }
};

export const isNumeric = (value: string): boolean => {
  return /^-{0,1}\d+$/.test(value);
};

export const isPhoneValid = (phoneNumber: string): boolean => {
  return /^((\+|0|00)?(420))?([67][0-9]{8})$/.test(phoneNumber);
};

export const isPukValid = (puk: string): boolean => {
  return /^\d{8}$/.test(puk);
};

export const isOTPValid = (otp: string): boolean => {
  return /^\d{6}$/.test(otp);
};


export const isNameValid = (name: string): boolean => {
  return /^[a-zA-ZŹáčćďéěíňóřšśťůúýžÁČĆĎÉĚÍŇÓŘŠŚŤŮÚÝŽÔôĽľäÄåÅöÖőŐüÜűŰńŃÆĺĹęĘ\\d ,.'\\-\\!\\?\\u00c0-\\u00ff\\u0100-\\u017f\\u1e00-\\u1eff\\u0027\\u002d\\u0060\\u0020]{1,75}$/.test(
    name
  );
};

export const isNicknamePartOfPassword = (email: string, password: string): string => {
  const emailPrefix = email.split('@')[0].toLowerCase();
  const emailPrefixReverse = emailPrefix.split('').reverse().join('');

  if (password.toLowerCase().includes(emailPrefix) || password.toLowerCase().includes(emailPrefixReverse)) {
    return I18n.t('ume.ext.changePassword.passwordNickname');
  }

  return '';
};
