import { History } from 'history';
import { O2Button, O2Indent, O2Section, O2TextField } from 'o2-theme-react';
import React, { FC, useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import * as ContactPersonActions from '../../redux/actions/contactPersonActions';
import * as SessionActions from '../../redux/actions/sessionActions';
import { Registration, Session } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { emailIsValid, isPhoneValid } from '../../utils';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import useAnalytics from '../../hooks/useAnalytics';

interface StateProps {
  registration: Registration;
  session: Session;
}

interface DispatchProps {
  registerPostpaidUser: (
    firstName: string,
    lastName: string,
    email: string,
    contactPhone: string,
    history: History,
    googleToken: string,
  ) => void;
  getUserInfo: () => void;
}

interface Props extends StateProps, DispatchProps {}
//SCR10
const RegisterPostpaidPage: FC<Props> = ({ registerPostpaidUser, registration}) => {
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailVerify, setEmailVerify] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [emailVerifyError, setEmailVerifyError] = useState<string>('');
  const [contactPhone, setContactPhone] = useState<string>('');
  const [contactPhoneError, setContactPhoneError] = useState<string>('');
  const history = useHistory<History>();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { pageLoaded, setFormSubmit } = useAnalytics();

  useEffect(() => {
    pageLoaded('2.6.2', I18n.t('ume.ext.postpaid.register.title'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleKeySubmitRegistration = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && isFormFilled()) {
      submitRegister();
    }
  };

  const isFormFilled = () => {
    return firstName.length > 0 && lastName.length > 0 && email.length > 0 && contactPhone.length > 0;
  };

  const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setEmail(value);
    if (value === '' || emailVerify === '' || value === emailVerify) {
      setEmailVerifyError('');
    } else {
      setEmailVerifyError(I18n.t('ume.ext.changeEmail.emailAgainNotSame'));
    }
  };

  const emailAgainChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setEmailVerify(value);
    if (value === '' || email === '' || value === email) {
      setEmailVerifyError('');
    } else {
      setEmailVerifyError(I18n.t('ume.ext.changeEmail.emailAgainNotSame'));
    }
  };

  const registerHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormSubmit('2.6.2');
    submitRegister();
  };

  const submitRegister = () => {
    let valid = true;

    if (!emailIsValid(email)) {
      setEmailError(I18n.t('ume.ext.registration.start.email.error'));
      valid = false;
    }
    if (email !== emailVerify) {
      valid = false;
    }

    if (!isPhoneValid(contactPhone)) {
      setContactPhoneError(I18n.t('ume.ext.registration.start.phone.notNumber'));
    }

    if (valid) {
      if (executeRecaptcha) {
        executeRecaptcha('umeRegistrationPostpaid').then((token: any) => {
          registerPostpaidUser(firstName, lastName, email, contactPhone, history, token);
        });
      }
    }
  }

  return (
    <>
      <form onSubmit={registerHandler}>
        <O2Section>
          <O2Indent>
            <h1 className='o2-typo__heading'>{I18n.t('ume.ext.postpaid.register.title')}</h1>
          </O2Indent>
          <O2Indent>{I18n.t('ume.ext.postpaid.register.description')}</O2Indent>
          <O2Indent>
            <O2TextField
              inline
              data-test-id='register-first-name'
              label={I18n.t('ume.ext.postpaid.register.firstname')}
              controlSize='medium'
              value={firstName}
              onChange={e => setFirstName(e.currentTarget.value)}
              onKeyDown={handleKeySubmitRegistration}
              disabled={registration.registered || registration.processing}
            />
          </O2Indent>
          <O2Indent>
            <O2TextField
              inline
              data-test-id='register-last-name'
              label={I18n.t('ume.ext.postpaid.register.lastname')}
              controlSize='medium'
              value={lastName}
              onChange={e => setLastName(e.currentTarget.value)}
              onKeyDown={handleKeySubmitRegistration}
              disabled={registration.registered || registration.processing}
            />
          </O2Indent>
          <O2Indent>
            <O2TextField
              inline
              data-test-id='register-phone'
              label={I18n.t('ume.ext.postpaid.register.contactPhone.label')}
              labelNote={I18n.t('ume.ext.postpaid.register.contactPhone.hint')}
              type='text'
              placeholder={I18n.t('ume.ext.postpaid.register.contactPhone.placeholder')}
              value={contactPhone}
              onChange={e => {
                setContactPhoneError('');
                setContactPhone(e.currentTarget.value.replace(/\s/g,''));
              }}
              onKeyDown={handleKeySubmitRegistration}
              disabled={registration.registered || registration.processing}
              validationText={contactPhoneError}
              validationType={contactPhoneError ? 'error' : undefined}
            />
          </O2Indent>
          <O2Indent>
            <O2TextField
              inline
              indentRight
              data-test-id='register-email'
              label={I18n.t('ume.ext.postpaid.register.email')}
              controlSize='medium'
              value={email}
              onChange={emailChangeHandler}
              onKeyDown={handleKeySubmitRegistration}
              disabled={registration.registered || registration.processing}
              validationText={emailError}
              validationType={emailError ? 'error' : undefined}
            />
          </O2Indent>
          <O2Indent>
            <O2TextField
              inline
              indentRight
              data-test-id='register-email-verify'
              label={I18n.t('ume.ext.postpaid.register.emailVerify')}
              controlSize='medium'
              value={emailVerify}
              onChange={emailAgainChangeHandler}
              onKeyDown={handleKeySubmitRegistration}
              disabled={registration.registered || registration.processing}
              validationText={emailVerifyError}
              validationType={emailVerifyError ? 'error' : undefined}
            />
          </O2Indent>
          <O2Indent standalone>
            <O2Button
              data-test-id='register-submit'
              color='primary'
              type='submit'
              loading={registration.processing ? 'right' : undefined}
              disabled={!isFormFilled() || registration.registered}
            >
              {I18n.t('ume.ext.postpaid.register.submit')}
            </O2Button>
          </O2Indent>
        </O2Section>
      </form>
    </>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  registration: state.registration,
  session: state.session
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  registerPostpaidUser: (
    firstName: string,
    lastName: string,
    email: string,
    contactPhone: string,
    history: History,
    googleToken: string,
  ) => dispatch(ContactPersonActions.registerPostpaidUser(firstName, lastName, email, contactPhone, history, googleToken)),
  getUserInfo: () => dispatch(SessionActions.loadUserInfoUnprotected(true))
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPostpaidPage);
