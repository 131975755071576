/// <reference path="./react-i18nify.d.ts" />
/// <reference path="./react-deep-force-update.d.ts" />

import { I18n } from 'react-i18nify-lite';
import deepForceUpdate from 'react-deep-force-update';
import * as React from 'react';
import ResourceApi from './api/resource-api';
import messagesCS from './resources/messagesCs';
import SharedObjectApi from './api/shared-objects-api';
import { getStore } from './redux/store/store';
import { addSharedObjectSessions } from './redux/actions/sharedObjectActions';
import { getApiPath } from './api/request-utils';

export const RESOURCE_APPLICATION = 'UME_EXT';
export const DEFAULT_LANGUAGE = 'cs';

let _rootComponent: React.Component;
let _availableTranslations: { [name: string]: boolean } = {};

export const initializeLangtexts = (): Promise<void> => {
  return refreshLangtexts(true).then(() => {
      return getLocale();
  });
};

export const getLocale = (): any => {
  return SharedObjectApi.getSharedObjectSession().then(res => {
     getStore().dispatch(addSharedObjectSessions(res.data));
     if (res.data && res.data.generalLoginData && res.data.generalLoginData.userPreferences && res.data.generalLoginData.userPreferences.locale.includes('en')) {
      I18n.setLocale('en');
     } else {
      I18n.setLocale('cs');
     }
     if (_rootComponent) {
      deepForceUpdate(_rootComponent)
    }
  }).catch(err =>{
    if (err.response && err.response.status === 401) {
      window.location.href = getApiPath(`auth/casLogin?redirectTo=${encodeURIComponent(
        window.location.href
      )}&internalLogin=false&checkOnly=true`);
    }
    I18n.setLocale('cs');
    if (_rootComponent) {
      deepForceUpdate(_rootComponent)
    }
  });
};
export const setRootComponent = (component: React.Component) => {
  _rootComponent = component;
};

export const refreshLangtexts = (updateComponents: boolean = false) => {
  let bundledTexts = { cs: messagesCS };
  return ResourceApi.getLangtexts(RESOURCE_APPLICATION)
    .then(res => {
      const translations = mergeTextTranslations(res.data, bundledTexts);
      _availableTranslations = {};
      Object.getOwnPropertyNames(translations).forEach(lang => (_availableTranslations[lang] = true));
      setTranslations(translations, updateComponents);
    })
    .catch(err => {
      console.error('Failed to load langtexts: ', err);
      setTranslations(bundledTexts, updateComponents);
    });
};

export const setTranslations = (translations: Object, updateComponents: boolean) => {
  I18n.setTranslations(translations); 
  if (_rootComponent && updateComponents) {
    deepForceUpdate(_rootComponent)
  }
};

const mergeTextTranslations = (serverTexts: any, targetTexts: any) => {
  if (!serverTexts || !serverTexts[RESOURCE_APPLICATION] || !serverTexts[RESOURCE_APPLICATION]['<ANY>']) {
    return targetTexts;
  }

  let appTexts = serverTexts[RESOURCE_APPLICATION]['<ANY>'];

  Object.getOwnPropertyNames(appTexts).forEach(lang => {
    let langTexts = appTexts[lang];

    let targetLangTexts = targetTexts[lang];
    if (!targetLangTexts) {
      targetLangTexts = targetTexts[lang] = {};
    }

    Object.getOwnPropertyNames(langTexts).forEach(key => {
      let target = targetLangTexts;
      let keyParts = key.split('.');
      keyParts.forEach((keyPart, index) => {
        if (index === keyParts.length - 1) {
          target[keyPart] = langTexts[key];
        } else {
          if (!target[keyPart]) {
            target[keyPart] = {};
          }
          target = target[keyPart];
        }
      });
    });
  });

  return targetTexts;
};
