import { DEVICE_API, sendPut } from './request-utils';


export default class DeviceApi {
  static assignCreate(msisdn: string) {
    return sendPut(DEVICE_API + 'create', { msisdn: msisdn }, null);
  }

  static assignFinish(msisdn: string, otp: string) {
    return sendPut(DEVICE_API + 'finish', { msisdn: msisdn, otp: otp }, null);
  }

  static assignFinishPUK(msisdn: string, puk: string) {
    return sendPut(DEVICE_API + 'finish', { msisdn: msisdn, puk: puk }, null);
  }
}
