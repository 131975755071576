import { O2PopupMessage, O2PopupMessageItem } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { removeMessage } from '../../redux/actions/messageActions';
import { Message } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';

interface StateProps {
  messages: Message[];
}

interface DispatchProps {
  deleteMessage: (id: number) => void;
}

interface Props extends StateProps, DispatchProps {}

const Snackbar: React.FC<Props> = ({ messages, deleteMessage }) => {
  const [timed, setTimed] = useState<number[]>([]);

  useEffect(() => {
    for (let i = 0; i < messages.length; i++) {
      if (!timed.find(id => messages[i].id === id)) {
        const message = messages[i];
        setTimed(state => [...state, message.id]);
        setTimeout(
          () => {
            deleteMessage(message.id);
            setTimed(state => state.filter(time => time !== message.id));
          },
          message.type === 'success' ? 3000 : 10000
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const renderMessages = () => {
    return messages.map(message => (
      <O2PopupMessageItem
        key={message.id}
        color={message.type}
        itemRemoving
        onClose={() => deleteMessage(message.id)}
        closeBtn={true}
      >
        {message.message}
      </O2PopupMessageItem>
    ));
  };

  return <O2PopupMessage>{renderMessages()}</O2PopupMessage>;
};

const mapStateToProps = (state: StoreState): StateProps => ({
  messages: state.messages
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  deleteMessage: (id: number) => dispatch(removeMessage(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
