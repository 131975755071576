import { Action } from '../actions/sessionActions';
import { Session } from './types';

const initialState: Session = {
  userInfo: {
    username: '',
    roles: []
  },
  processing: false,
  deviceValidForPairing: false,
  personValidForPairing: false,
  ATGLink: '',
  wasRequested: false,
};

export default (state: Session = initialState, action: Action): Session => {
  switch (action.type) {
    case 'SET_USER_START':
      return {
        ...state,
        userInfo: {
          username: '',
          roles: []
        },
        processing: true,
        notLogged: undefined
      };
    case 'SET_USER_SUCCESS':
      return {
        ...state,
        userInfo: action.userInfo,
        deviceValidForPairing: action.deviceValidForPairing,
        personValidForPairing: action.personValidForPairing,
        processing: false,
        notLogged: !action.userInfo.logged,
        ATGLink: action.ATGLink,
        wasRequested: true,
      };
    case 'SET_USER_FAIL':
      return {
        ...state,
        processing: false,
        error: action.error,
        notLogged: action.code !== undefined
      };
    default:
      return state;
  }
};
