import { O2Button, O2Indent, O2InfoMessage, O2Section } from 'o2-theme-react';
import React, { FC, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

import { resendEmail } from '../../redux/actions/verificationActions';
import { Verification } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import useAnalytics from '../../hooks/useAnalytics';

interface StateProps {
  verification: Verification;
}

interface DispatchProps {
  resendEmail: (resendSecret: string) => void;
}

interface UrlProps {
  token: string;
}

interface Props extends RouteComponentProps, UrlProps, DispatchProps, StateProps {}
//SCR01
const ThanksCheckEmail: FC<Props> = props => {
  const urlProps = props.match.params as UrlProps;
  const { pageLoaded, setFormSubmit } = useAnalytics();
  const [resendCounter, setResendCounter] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(false);

  useEffect(() => {
    pageLoaded('666.1.10.1', I18n.t('ume.ext.registration.followEmail.header'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resendEmailHandler = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    setResendCounter(resendCounter + 1);
    setIsDisabled(true);
    if (resendCounter < 3) {
      setTimeout(() => {
        setIsDisabled(false);
      }, 90000);
    }
    
    setFormSubmit('666.1.10.1');
    if (props.verification.token !== undefined && props.verification.token !== '') {
      props.resendEmail(props.verification.token);
    } else {
      props.resendEmail(urlProps.token);
    }
  };

  return (
    <O2Section>
      <O2InfoMessage color="neutral">
        <O2Indent>
          {/* 010 */}
          <div className="o2-typo--heading-h6">{I18n.t('ume.ext.registration.followEmail.description', { email: localStorage.getItem('email')})}</div>
        </O2Indent>
        {/* 011 */}
        <O2Indent top="close">
          {I18n.t('ume.ext.registration.followEmail.resendDescription')}
        </O2Indent>
        <O2Indent>
          {/* 012 */}
          <O2Button
            color="primary"
            fullWidth="xs"
            disabled={isDisabled}
            onClick={resendEmailHandler}
          >
            {I18n.t('ume.ext.registration.followEmail.resend')}
          </O2Button>
        </O2Indent>
      </O2InfoMessage>
    </O2Section>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  verification: state.verification
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  resendEmail: (resendSecret: string) => dispatch(resendEmail(resendSecret))
});

export default connect(mapStateToProps, mapDispatchToProps)(ThanksCheckEmail);
