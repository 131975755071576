import { O2Header, O2HeaderLinks, O2HeaderLogo, O2HeaderNavig, O2HeaderNavigContent, O2HeaderControls, O2HeaderNavigItem } from 'o2-theme-react';
import React, { useEffect, useState } from 'react';
import UserInfo from './UserInfo';
import StoreState from '../../redux/store/storeState';
import { Session } from '../../redux/reducers/types';
import { connect, useSelector } from 'react-redux';
import HeaderLink from '../common/HeaderLink';
import { I18n } from 'react-i18nify-lite';
import { getApiPath } from '../../api/request-utils';

interface StateProps {
  session: Session;
}

interface Props extends StateProps {}

const Header: React.FC<Props> = ({ session }) => {
  const apfThemeLocation = (window as any).g_apfThemeLocation;
  const { userInfo } = session;
  const [ showBasketBadge, setShowBasketBadge ] = useState<boolean>(false);
  const sharedObjectSession = useSelector<any, any>(
    state => state.sharedObjectSession
  );

  useEffect(() => {
    if (sharedObjectSession) {
      if (sharedObjectSession?.sharedObjectSession?.orderBasket?.itemCount && sharedObjectSession?.sharedObjectSession?.orderBasket?.itemCount > 0) {
        setShowBasketBadge(true)
      }
    }
  }, [ sharedObjectSession ])

  return (
    <O2Header>
      <O2HeaderControls>
        <a href="https://moje.o2.cz/web/o2/orderbasket/-/order/displayBasket" className="o2-header__link o2-header__link--icon">
          <span className="o2-icon o2-icon--basket o2-icon--xl o2-icon--indent-right"></span>
          {showBasketBadge &&
            <span className="o2-header__state o2-header__state--basket"></span>
          }
          <span className="h-show-sm-up-inline">{I18n.t('ume.ext.header.basket')}</span>
        </a>
        <a href={getApiPath('deeplink/public/v1/deeplink?command=ETNHOME')} className="o2-header__link o2-header__link--icon h-show-md-up-inline">
          <span className="o2-icon o2-icon--home o2-icon--xl o2-icon--indent-right"></span>
          {I18n.t('ume.ext.header.o2')}
        </a>
        {userInfo && userInfo.logged &&
          <a href="#o2-header--popover-moje-o2-contact-user" className="o2-header__link o2-header__link--user js-popover" data-popover-elm-pad="-14">
          <span className="o2-header__link-text js-tooltip tipso_style" data-tooltip-target="#o2-11-3-1-029-tooltip">
            {I18n.t('ume.ext.header.customer')}: {userInfo.firstName || userInfo.lastName ? userInfo.firstName + ' ' + userInfo.lastName : userInfo.username}
            <br />
            <span>{I18n.t('ume.ext.header.o2id')}: {userInfo.email} </span>
          </span>
          <UserInfo />
        </a>
        }
        {userInfo && !userInfo.logged &&
          <a href={getApiPath(`auth/casLogin?redirectTo=${encodeURIComponent(window.location.href)}&internalLogin=false&forceLogin=true`)} className='o2-header__link'>
            {I18n.t('ume.ext.header.login')}
          </a>
        }
      </O2HeaderControls>
      <O2HeaderLinks>
        <O2HeaderLogo href={(userInfo && userInfo.logged && userInfo.subscriberType === 'O2PRP') ? getApiPath('deeplink/public/v1/deeplink?command=PRPHOME') : getApiPath('deeplink/public/v1/deeplink?command=POPHOME')} src={apfThemeLocation + '/images/header/logo-header-moje-o2.png'} />
        <O2HeaderNavig>
          {userInfo && userInfo.logged && 
            <O2HeaderNavigContent>
              <O2HeaderNavigItem>
                <HeaderLink
                  onClick={() => {
                    if (userInfo.subscriberType === 'O2PRP') {
                      window.location.href = 'http://pwsc-a-moje.o2.cz/dashboard';
                    } else if (userInfo.subscriberType === 'O2POP') {
                      window.location.href = 'https://moje.o2.cz/web/o2/userdashboard';
                    }
                  }}
                >
                  {I18n.t('ume.ext.header.services')}
                </HeaderLink>
              </O2HeaderNavigItem>
              <O2HeaderNavigItem>
                <HeaderLink
                  onClick={() => {
                    if (userInfo.subscriberType === 'O2PRP') {
                      window.location.href = 'http://pwsc-a-moje.o2.cz/history/spent';
                    } else if (userInfo.subscriberType === 'O2POP') {
                      window.location.href = 'https://moje.o2.cz/web/o2/billingmanagement';
                    }
                  }}
                >
                  {I18n.t('ume.ext.header.invoices')}
                </HeaderLink>
              </O2HeaderNavigItem>
              <O2HeaderNavigItem>
                <HeaderLink
                  onClick={() => {
                    if (userInfo.subscriberType === 'O2PRP') {
                      window.location.href = 'http://pwsc-a-moje.o2.cz/history/documents';
                    } else if (userInfo.subscriberType === 'O2POP') {
                      window.location.href = 'https://moje.o2.cz/web/o2/documents';
                    }
                  }}
                >
                  {I18n.t('ume.ext.header.documents')}
                </HeaderLink>
              </O2HeaderNavigItem>
            </O2HeaderNavigContent>
          }
        </O2HeaderNavig>
      </O2HeaderLinks>
    </O2Header>
  );
};

const mapStateToProps = (state: StoreState): StateProps => ({
  session: state.session
});

export default connect(mapStateToProps, null)(Header);
