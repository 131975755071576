import { O2Button, O2Indent, O2Section, O2TextField, O2Loader } from 'o2-theme-react';
import React, { useState, useEffect } from 'react';
import { I18n } from 'react-i18nify-lite';
import { useHistory } from 'react-router-dom';
import { Session, UpdatingContactData } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { ThunkDispatch } from 'redux-thunk';
import { getContactPersonData, updateName } from '../../redux/actions/contactPersonEditingActions';
import * as SessionActions from '../../redux/actions/sessionActions';
import { connect } from 'react-redux';
import { getApiPath } from '../../api/request-utils';
import { getStore } from '../../redux/store/store';
import { addDangerKahlMessage } from '../../redux/actions/kahlInfoActions';

interface StateProps {
  session: Session;
  updatingContactData: UpdatingContactData;
}

interface DispatchProps {
  updateName: (
    koId: string,
    firstName: string,
    middleName: string,
    lastName: string,
  ) => void;
  getUserInfo: () => void;
  getContactPersonData: (koId: string) => void;
}

interface Props extends StateProps, DispatchProps {}

const ChangeName: React.FC<Props> = ({ updateName, session, getUserInfo, getContactPersonData, updatingContactData }) => {
  const [ firstName, setFirstName ] = useState<string>('');
  const [ middleName, setMiddleName ] = useState<string>('');
  const [ lastName, setLastName ] = useState<string>('');
  const history = useHistory();
  const [isLoadingUserData, setIsLoadingUserData] = useState<boolean>(true);
  const [ blank, setBlank ] = useState<boolean>(false);

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (session.wasRequested) {
      if (session.userInfo.username === '' || session.userInfo.username === null) {
        window.location.href = getApiPath(`auth/casLogin?redirectTo=${encodeURIComponent(
          window.location.href
        )}&internalLogin=false&forceLogin=true`);
      } else {
        checkAuthorization();
        setIsLoadingUserData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ session.wasRequested, session.userInfo.username])

  const isFormChanged = () => {
    if (
      updatingContactData.data && 
      (
        firstName !== updatingContactData.data.firstName ||
        middleName !== updatingContactData.data.middleName ||
        lastName !== updatingContactData.data.lastName
      )
    ) return true

    return false
  }


  useEffect(() => {
    if (updatingContactData.data) {
      setFirstName(updatingContactData.data.firstName ? updatingContactData.data.firstName : '');
      setMiddleName(updatingContactData.data.middleName ? updatingContactData.data.middleName : '');
      setLastName(updatingContactData.data.lastName ? updatingContactData.data.lastName : '');
    }
  }, [updatingContactData.data])

  const checkAuthorization = () => {
    if (session.userInfo.subscriberType === 'O2PRP' && session.userInfo.koId) {
      getContactPersonData(session.userInfo.koId);
    } else {
      getStore().dispatch(addDangerKahlMessage('ID1306'));
      setBlank(true);
    }
  }

  const handleKeySubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && firstName.length > 1 && lastName.length > 1) {
      submitUpdateName();
    }
  };

  const submitUpdateName = () => {
    let valid = true;

    if (firstName.length < 2) {
      valid = false;
    }

    if (lastName.length < 2) {
      valid = false;
    }

    if (valid) {
      updateName(
        session.userInfo.koId ? session.userInfo.koId : '',
        firstName,
        middleName,
        lastName
      );
    }
  }

  if (blank) {
    return (
      <O2Section></O2Section>
      )
  } else if (isLoadingUserData) {
    return (
      <O2Loader>{I18n.t('ume.ext.registration.start.loading')}</O2Loader>
      )
  } else {
    return (
      <O2Section>
        <O2Indent>
          <h1 className='o2-typo__heading'>{I18n.t('ume.ext.changeName.title')}</h1>
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            label={I18n.t('ume.ext.changeName.firstName')}
            controlSize='medium'
            type='text'
            data-test-id='change-name-first-name'
            value={firstName}
            onChange={e => setFirstName(e.currentTarget.value)}
            onKeyDown={handleKeySubmit}
          />
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            label={I18n.t('ume.ext.changeName.middleName')}
            controlSize='medium'
            type='text'
            data-test-id='change-name-middle-name'
            value={middleName}
            onChange={e => setMiddleName(e.currentTarget.value)}
            onKeyDown={handleKeySubmit}
          />
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            label={I18n.t('ume.ext.changeName.lastName')}
            controlSize='medium'
            type='text'
            data-test-id='change-name-last-name'
            value={lastName}
            onChange={e => setLastName(e.currentTarget.value)}
            onKeyDown={handleKeySubmit}
          />
        </O2Indent>
        <O2Indent standalone>
          <O2Button
            indentRight
            data-test-id='change-name-back'
            color="transparent"
            onClick={() => history.goBack()}
            >
              {I18n.t('ume.ext.changeName.linkBack')}
          </O2Button>
          <O2Button
            indentRight
            data-test-id='change-name-submit'
            disabled={Boolean(firstName.length < 2 || lastName.length < 2) || !isFormChanged()}
            color='primary'
            onClick={submitUpdateName}
          >
            {I18n.t('ume.ext.changeName.action.confirm')}
          </O2Button>
        </O2Indent>
      </O2Section>
    );
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  session: state.session,
  updatingContactData: state.updatingContactData,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  updateName: (
    koId: string,
    firstName: string,
    middleName: string,
    lastName: string,
  ) => dispatch(updateName(koId, firstName, middleName,lastName)),
  getUserInfo: () => dispatch(SessionActions.loadUserInfoUnprotected(false)),
  getContactPersonData: (koId: string) => dispatch(getContactPersonData(koId))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangeName);
