import { History } from 'history';
import { Dispatch } from 'redux';

import ContactPersonApi from '../../api/contact-person-api';
import { checkSuccessKahlMessages, checkDangerKahlMessages, redirectToStandaloneKahl } from '../../api/request-utils';

export type Action =
  | {
      type: 'REGISTRATION_START';
    }
  | {
      type: 'REGISTRATION_SUCCESS';
    }
  | {
      type: 'REGISTRATION_FAIL';
      error: string;
      code?: number;
    }
  | {
    type: 'REGISTRATION_POSTPAID_START';
  }
  | {
      type: 'REGISTRATION_POSTPAID_SUCCESS';
    }
  | {
      type: 'REGISTRATION_POSTPAID_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'CHANGE_PASSWORD_START';
    }
  | {
      type: 'CHANGE_PASSWORD_SUCCESS';
    }
  | {
      type: 'CHANGE_PASSWORD_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'CREATE_PASSWORD_POSTPAID_START';
    }
  | {
      type: 'CREATE_PASSWORD_POSTPAID_SUCCESS';
    }
  | {
      type: 'CREATE_PASSWORD_POSTPAID_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'RESET_PASSWORD_REQUEST_START';
    }
  | {
      type: 'RESET_PASSWORD_REQUEST_SUCCESS';
    }
  | {
      type: 'RESET_PASSWORD_REQUEST_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'RESET_PASSWORD_START';
    }
  | {
      type: 'RESET_PASSWORD_SUCCESS';
    }
  | {
      type: 'RESET_PASSWORD_FAIL';
      error: string;
      code?: number;
    }
  | {
      type: 'EMAIL_CHANGE_CONFIRM_START';
    }
  | {
      type: 'EMAIL_CHANGE_CONFIRM_SUCCESS';
    }
  | {
      type: 'EMAIL_CHANGE_CONFIRM_FAIL';
      error: string;
      code?: number;
    };

export const register = (
  firstName: string,
  middleName: string,
  lastName: string,
  email: string,
  contactPhone: string,
  history: History,
  googleToken: string,
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'REGISTRATION_START'
    });

    return ContactPersonApi.register({ firstName, middleName, lastName, email, contactPhone, googleToken })
      .then(res => {
        checkSuccessKahlMessages(res.data);
        const token = res.data;
        dispatch({
          type: 'REGISTRATION_SUCCESS'
        });
        localStorage.setItem('email', email);
        history.push(`/ume/thanks/${token}`);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }

        return dispatch({
          type: 'REGISTRATION_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const registerPostpaidUser = (
  firstName: string,
  lastName: string,
  email: string,
  contactPhone: string,
  history: History,
  googleToken: string,
) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'REGISTRATION_POSTPAID_START'
    });

    return ContactPersonApi.registerPostpaid({ firstName, lastName, email, contactPhone, googleToken })
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'REGISTRATION_POSTPAID_SUCCESS'
        });
        history.push(`/ume/postpaid-thanks/`);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }

        return dispatch({
          type: 'REGISTRATION_POSTPAID_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const changePassword = (oldPassword: string, newPassword: string, otp?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'CHANGE_PASSWORD_START'
    });

    return ContactPersonApi.changePassword(oldPassword, newPassword, otp)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'CHANGE_PASSWORD_SUCCESS'
        })
        window.location.replace(res.data);
      }
      )
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'CHANGE_PASSWORD_FAIL',
          error: err.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const createPasswordPostpaid = (password: string, verificationSecret: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'CREATE_PASSWORD_POSTPAID_START'
    });

    return ContactPersonApi.createPasswordPostpaid(password, verificationSecret)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'CREATE_PASSWORD_POSTPAID_SUCCESS'
        });
        window.location.replace(res.data);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'CREATE_PASSWORD_POSTPAID_FAIL',
          error: err.message.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const resetPasswordRequest = (email: string, history: History, world: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'RESET_PASSWORD_REQUEST_START'
    });

    return ContactPersonApi.resetPasswordRequest(email, world)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'RESET_PASSWORD_REQUEST_SUCCESS'
        });
        localStorage.setItem('email', email);
        history.push(`/ume/thanks-recover/${res.data}`);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'RESET_PASSWORD_REQUEST_FAIL',
          error: err.message.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const resetPassword = (verificationSecret: string, newPassword: string, otp?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'RESET_PASSWORD_START'
    });

    return ContactPersonApi.resetPassword(verificationSecret, newPassword, otp)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'RESET_PASSWORD_SUCCESS'
        });
        window.location.replace(res.data);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'RESET_PASSWORD_FAIL',
          error: err.message.toString(),
          code: err.response && err.response.status
        });
      });
  };
};

export const confirmEmailChange = (password: string, verificationSecret: string, otp?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'EMAIL_CHANGE_CONFIRM_START'
    });

    return ContactPersonApi.confirmEmailChange(password, verificationSecret, otp)
      .then(res => {
        checkSuccessKahlMessages(res.data);
        dispatch({
          type: 'EMAIL_CHANGE_CONFIRM_SUCCESS'
        });
        window.location.replace(res.data);
      })
      .catch(err => {
        if (err.response.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        } else {
          checkDangerKahlMessages(err.response.data);
        }
        return dispatch({
          type: 'EMAIL_CHANGE_CONFIRM_FAIL',
          error: err.message.toString(),
          code: err.response && err.response.status
        });
      });
  };
};
