import { Dispatch } from 'redux';

import ContactPersonApi from '../../api/contact-person-api';
import SessionApi from '../../api/session-api';
import { UserInfo } from '../../api/types';
import { checkSuccessKahlMessages, checkDangerKahlMessages, redirectToStandaloneKahl, getApiPath } from '../../api/request-utils';

export type Action =
  | { type: 'SET_USER_START' }
  | {
      type: 'SET_USER_SUCCESS';
      userInfo: UserInfo;
      deviceValidForPairing?: boolean;
      personValidForPairing?: boolean;
      ATGLink: string;
    }
  | {
      type: 'SET_USER_FAIL';
      error: string;
      code?: number;
    };

export const loadUserInfo = (withValidate: boolean = false) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'SET_USER_START'
    });

    return SessionApi.getUserInfo()
      .then(res => {
        const userData = res.data;

        if (withValidate) {
          ContactPersonApi.validatePerson()
            .then(res => {
              dispatch({
                type: 'SET_USER_SUCCESS',
                userInfo: userData,
                personValidForPairing: true,
                ATGLink: '',
              });
            })
            .catch(err => {
              dispatch({
                type: 'SET_USER_SUCCESS',
                userInfo: userData,
                personValidForPairing: false,
                ATGLink: err.response.data
              });
            });
        } else {
          dispatch({
            type: 'SET_USER_SUCCESS',
            userInfo: userData,
            ATGLink: '',
          });
        }
      })
      .catch(err => {
        if (!err || err.message !== 'login_redirect') {
          dispatch({
            type: 'SET_USER_FAIL',
            error: err.message,
            code: err.response.status
          });
        }
      });
  };
};

export const loadUserInfoUnprotected = (withValidate: boolean = true) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: 'SET_USER_START'
    });

    return SessionApi.getUserInfoUnprotected()
      .then(res => {
        checkSuccessKahlMessages(res.data);
        const userData = res.data;

        if (!userData.logged && !userData.anonymous) {
          window.location.href = getApiPath(`auth/casLogin?redirectTo=${encodeURIComponent(
            window.location.href
          )}&internalLogin=false&checkOnly=true`);
        }

        if (withValidate && userData.roles?.includes('LOA0')) {
          ContactPersonApi.validateDevice()
            .then(res2 => {
              checkSuccessKahlMessages(res2.data);
              dispatch({
                type: 'SET_USER_SUCCESS',
                userInfo: userData,
                deviceValidForPairing: true,
                ATGLink: '',
              });
            })
            .catch(err2 => {
              dispatch({
                type: 'SET_USER_SUCCESS',
                userInfo: userData,
                deviceValidForPairing: false,
                ATGLink: '',
              });
            });
        } else {
          dispatch({
            type: 'SET_USER_SUCCESS',
            userInfo: userData,
            ATGLink: '',
          });
        }
      })
      .catch(err => {
        console.log(err);
        
        if (err.response?.status === 410 && err.response.data.resultCode) {
          redirectToStandaloneKahl(err.response.data.resultCode, 'UME_EXT')
        }
        checkDangerKahlMessages(err.response?.data);
        if (!err || err.message !== 'login_redirect') {
          dispatch({
            type: 'SET_USER_FAIL',
            error: err.message,
            code: err.response?.status
          });
        }
      });
  };
};