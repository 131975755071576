import { O2Button, O2Checkbox, O2FieldLabel, O2Indent, O2Section, O2TextField } from 'o2-theme-react';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { I18n } from 'react-i18nify-lite';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';
import useAnalytics from '../../hooks/useAnalytics';

import * as PasswordAction from '../../redux/actions/passwordActions';
import * as VerificationAction from '../../redux/actions/verificationActions';
import { Verification } from '../../redux/reducers/types';
import StoreState from '../../redux/store/storeState';
import { isPasswordValid } from '../../utils';
import HeadingWithInfo from '../common/HeadingWithInfo';

interface StateProps {
  verification: Verification;
}

interface DispatchProps {
  verifySecret: (secret: string, acceptOnly: string[], setInvalidSecret: any) => void;
  createPasswordPostpaid: (password: string, verificationSecret: string) => void;
}

interface Props extends StateProps, DispatchProps {}
//SCR12
const FinishRegistrationPostpaid: React.FC<Props> = ({ verification, verifySecret, createPasswordPostpaid }) => {
  const [verSecret, setVerSecret] = useState<string>('');
  const [invalidSecret, setInvalidSecret] = useState<boolean>(false);
  const [ showPassword , setShowPassword ] = useState<boolean>(false);

  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const [repeatPassword, setRepeatPassword] = useState<string>('');
  const [repeatPasswordError, setRepeatPasswordError] = useState<string>('');

  const location = useLocation();
  const { pageLoaded, setFormSubmit } = useAnalytics();

  useEffect(() => {
    pageLoaded('2.6.4', I18n.t('ume.ext.postpaid.registerFinish.title'));
    const { secret } = queryString.parse(location.search);

    if (secret) {
      verifySecret(secret as string, ['NEW_POSTPAID_CONTACT_PERSON'], setInvalidSecret);
      setVerSecret(secret as string);
    } else {
      verifySecret('invalid', ['NEW_POSTPAID_CONTACT_PERSON'], setInvalidSecret);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeySubmitSaveNewPassword = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && passwordError === '' && repeatPasswordError === '') {
      registerPassword();
    }
  };

  const passwordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;

    setPasswordError(isPasswordValid(value));
    setPassword(value);
    if (value === '' || repeatPassword === '' || value === repeatPassword) {
      setRepeatPasswordError('');
    } else {
      setRepeatPasswordError(I18n.t('ume.ext.newPassword.differentPassword'));
    }
  };

  const repeatPasswordChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setRepeatPassword(value);

    if (password === '' || value === '' || password === value) {
      setRepeatPasswordError('');
    } else {
      setRepeatPasswordError(I18n.t('ume.ext.newPassword.differentPassword'));
    }
  };

  const registerPassword = () => {
    if (verification.emailType && verification.emailType?.verificationEmailType === 'NEW_POSTPAID_CONTACT_PERSON') {
      setFormSubmit('2.6.4');
      createPasswordPostpaid(password, verSecret);
    }
  };

  if (verification && verification.processing) {
    return (
      <HeadingWithInfo headingText={I18n.t('ume.ext.newPassword.loading')} descriptionText={I18n.t('ume.ext.registration.start.description')} />
    );
  } else {
    return (
      <O2Section>
        <O2Indent>
          <h1 className='o2-typo__heading'>{I18n.t('ume.ext.postpaid.registerFinish.title')}</h1>
        </O2Indent>
        <O2Indent>{I18n.t('ume.ext.postpaid.registerFinish.description')}</O2Indent>
        <O2Indent>
          <O2FieldLabel block={true}>
            {I18n.t('ume.ext.postpaid.registerFinish.email')}
          </O2FieldLabel>
          <span>{verification.emailType && verification.emailType?.email}</span>
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            type={showPassword ? 'text' : 'password'}
            data-test-id='new-password-password'
            label={I18n.t('ume.ext.postpaid.password.label')}
            controlSize='medium'
            value={password}
            onChange={passwordChangeHandler}
            onKeyDown={handleKeySubmitSaveNewPassword}
            disabled={invalidSecret}
            validationText={passwordError}
            validationType={passwordError ? 'error' : undefined}
          />
        </O2Indent>
        <O2Indent>
          <O2TextField
            inline
            type={showPassword ? 'text' : 'password'}
            data-test-id='new-password-password-repeat'
            label={I18n.t('ume.ext.postpaid.passwordVerify.label')}
            controlSize='medium'
            value={repeatPassword}
            onChange={repeatPasswordChangeHandler}
            onKeyDown={handleKeySubmitSaveNewPassword}
            disabled={invalidSecret}
            validationText={repeatPasswordError}
            validationType={repeatPasswordError ? 'error' : undefined}
          />
        </O2Indent>
        <O2Indent>
          <O2Checkbox
            id="show-password"
            checked={showPassword}
            onChange={() => setShowPassword(!showPassword)}
            disabled={invalidSecret}
            label={I18n.t('ume.ext.postpaid.passwordVerify.showPassword')}
            data-test-id='change-email-show-password'
          />
        </O2Indent>
        <O2Indent standalone>
          <O2Button
            data-test-id='new-password-submit'
            color='primary'
            disabled={(passwordError !== '' || repeatPasswordError !== '') || invalidSecret}
            onClick={registerPassword}
          >
            {I18n.t('ume.ext.newPassword.confirmPassword')}
          </O2Button>
        </O2Indent>
      </O2Section>
    );
  }
};

const mapStateToProps = (state: StoreState): StateProps => ({
  verification: state.verification
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => ({
  verifySecret: (verificationSecret: string, acceptOnly: string[], setInvalidSecret: any) => dispatch(VerificationAction.verify(verificationSecret, acceptOnly, setInvalidSecret)),
  createPasswordPostpaid: (password: string, verificationSecret: string) =>
    dispatch(PasswordAction.createPasswordPostpaid(password, verificationSecret))
});

export default connect(mapStateToProps, mapDispatchToProps)(FinishRegistrationPostpaid);
